import type { ProfileFragment } from '~/shared/graphql/user/CurrentUserQuery.generated';

const getGameLanguage = (
  locale: string,
  featureFlags?: FeatureFlags,
  profile?: ProfileFragment,
) => {
  const isChinese = locale === 'zh';
  const isJapanese = locale === 'ja';
  const isLocaleLanguage = isChinese || isJapanese;

  const isChineseFlagActive = featureFlags?.['gameNamesInEnglishCheckbox'];
  const isJapaneseFlagActive =
    featureFlags?.['japaneseGameNamesInEnglishCheckbox'];
  const isFlagActive = isChineseFlagActive || isJapaneseFlagActive;

  const isEnglishPreference = profile?.settings?.gameNamesInEnglish === '1';
  const isGameNameInEnglish =
    isFlagActive && isEnglishPreference && isLocaleLanguage;
  const language = isGameNameInEnglish ? 'en' : locale;

  return language;
};

export default getGameLanguage;

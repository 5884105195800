import useFeature from '~/client/hooks/feature/useFeature';
import Cookie from '~/shared/utils/Cookie';

const useIsStepByStepEnabled = () => {
  const isStepByStepRegistrationEnabled = useFeature(
    'isStepByStepRegistrationEnabled',
  );

  const bypassCookie = !!parseInt(
    Cookie.get('IS_STEP_BY_STEP_REGISTRATION_ENABLED') as string,
  );
  if (bypassCookie) {
    return true;
  }
  return isStepByStepRegistrationEnabled;
};

export default useIsStepByStepEnabled;

import useAgentData from '~/client/hooks/useAgentData';
import useSiteConfig from '~/client/hooks/useSiteConfig';
import CmsContainer from './CmsContainer';
import type CmsViewProps from './CmsViewProps';
import parseContent from './helpers/parseContent';
import useCmsView from './useCmsView';
import useCashierStore from '@/client/hooks/useCashierStore';

const CmsView: FC<CmsViewProps> = (props) => {
  const { isMobile } = useAgentData();
  const { className, content, isFixedImgSize } = props;
  const { node, isLoggedIn, handleClick } = useCmsView(props);
  const { domain } = useSiteConfig();
  const { setOpen } = useCashierStore();
  return (
    <CmsContainer
      ref={node}
      className={className}
      loggedIn={isLoggedIn}
      onClick={handleClick}
      isArticle={isFixedImgSize}
    >
      {parseContent({ content, domain, isMobile, setCashierOpen: setOpen })}
    </CmsContainer>
  );
};

export default CmsView;

/* eslint-disable no-restricted-imports */
'use client';
import { ApolloProvider } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { ThemeProvider as StyledProvider } from 'styled-components';
import ToastContextProvider from '~/client/layout/DefaultLayout/LayoutContext/Toast/ToastContextProvider';
import AppContext from '~/shared/context/AppContext';
import useApollo from '~/shared/hooks/useApollo';
import getCurrentTheme from '~/shared/utils/theme/getCurrentTheme';
import CookieContextProvider from './CookieContextProvider';
import RouterContextProvider from './RouterContextProvider';

type Props = AppContextProps & { apolloState?: string; gqlServerUri?: string };

const ClientPagesRouterAdapter: FC<Props> = ({
  children,
  apolloState = '{}',
  gqlServerUri,
  ...appContextProps
}) => {
  if (process.env.NODE_ENV === 'development') {
    loadDevMessages();
    loadErrorMessages();
  }

  const apolloClient = useApollo({
    initialState: JSON.parse(apolloState),
    uri: gqlServerUri,
  });
  return (
    <RouterContextProvider>
      <ApolloProvider client={apolloClient}>
        <AppContext.Provider value={appContextProps}>
          <CookieContextProvider>
            <StyledProvider
              theme={getCurrentTheme(
                appContextProps.site,
                appContextProps.colorScheme ?? 'dark',
              )}
            >
              <ToastContextProvider>{children}</ToastContextProvider>
            </StyledProvider>
          </CookieContextProvider>
        </AppContext.Provider>
      </ApolloProvider>
    </RouterContextProvider>
  );
};

export default ClientPagesRouterAdapter;

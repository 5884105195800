import { Carousel } from '@heathmont/moon-core-tw';
import {
  ControlsChevronLeft,
  ControlsChevronRight,
} from '@heathmont/moon-icons-tw';

type Props = {
  direction: 'left' | 'right';
  className?: string;
  iconClassName?: string;
};

const CarouselButton: FC<Props> = ({ direction, className, iconClassName }) => {
  if (direction === 'right') {
    return (
      <Carousel.RightArrow className={className}>
        <ControlsChevronRight className={iconClassName} />
      </Carousel.RightArrow>
    );
  }
  return (
    <Carousel.LeftArrow className={className}>
      <ControlsChevronLeft className={iconClassName} />
    </Carousel.LeftArrow>
  );
};

export default CarouselButton;

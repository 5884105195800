import useCmsContainer from '~/client/shared/cms/CmsContainer/useCmsContainer';
import type CmsContainerProps from './CmsContainerProps';
import CmsView from './CmsView';

const CmsContainer: FC<CmsContainerProps> = (props) => {
  const { active, className, article, optInOnInit, isFixedImgSize } = props;
  const { finalContent, contentOptIn } = useCmsContainer(props);
  if (!active) {
    return (
      <CmsView
        content={finalContent}
        className={className}
        isFixedImgSize={isFixedImgSize}
      />
    );
  }
  return (
    <CmsView
      className={className}
      participating={article?.participating}
      content={finalContent}
      optInOnInit={optInOnInit}
      contentOptIn={contentOptIn}
      isFixedImgSize={isFixedImgSize}
      handle={article?.handle}
    />
  );
};

export default CmsContainer;

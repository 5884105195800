import { mergeClassnames } from '@heathmont/moon-core-tw';
import CmsContainer from '~/client/shared/cms/CmsContainer';
import type CmsContainerProps from '../../CmsContainer/CmsContainerProps';

type Props = { isCollapsed?: boolean; loggedIn?: boolean } & CmsContainerProps;

const CmsView: FC<Props> = ({ isCollapsed, loggedIn, content, article }) => (
  <CmsContainer
    className={mergeClassnames(
      isCollapsed &&
        'relative max-h-52 overflow-hidden text-ellipsis after:bg-gradient-to-t after:from-gohan',
      isCollapsed &&
        'after:absolute after:bottom-0 after:left-0 after:h-12 after:w-full md:max-h-48 md:after:h-[4.5rem]',
      loggedIn && '[&_.pt-button.cms_visibility_logged_out]:hidden',
      !loggedIn && '[&_.pt-button.cms_visibility_logged_in]:hidden',
      '[&_p]:my-4 [&_p]:text-moon-16 [&_p]:!text-bulma',
      '[&_span]:my-4 [&_span]:text-moon-16 [&_span]:!text-bulma',
      '[&_ol]:[margin-block-end:1rem] [&_ol]:[margin-block-start:1rem] [&_ol]:[margin-inline-start:0]',
      '[&_ol]:list-decimal [&_ol]:text-moon-16 [&_ol]:[padding-inline-start:2.5rem] [&_ol]:[margin-inline-end:0]',
      '[&_ul]:[margin-block-end:1rem] [&_ul]:[margin-block-start:1rem] [&_ul]:[margin-inline-start:0]',
      '[&_ul]:text-moon-16 [&_ul]:[padding-inline-start:2.5rem] [&_ul]:[margin-inline-end:0]',
      '[&_h1]:text-bulma [&_h2]:text-bulma [&_h3]:text-bulma [&_table]:text-moon-16 [&_h1_span]:!font-headline ',
    )}
    content={content}
    article={article}
  />
);

export default CmsView;

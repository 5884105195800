import { createContext } from 'react';
import getTheme from '~/shared/utils/theme/getTheme';

type ContextProps = {
  theme: Theme;
  setTheme: SetState<Theme>;
  colorScheme: ColorScheme;
  setColorScheme: SetState<ColorScheme>;
};

export default createContext<ContextProps>({
  theme: getTheme('bitcasinoLight'),
  setTheme: () => undefined,
  colorScheme: 'light',
  setColorScheme: () => undefined,
});

import { mergeClassnames } from '@heathmont/moon-core-tw';
import dynamic from 'next/dynamic';
import useAgentData from '~/client/hooks/useAgentData';
import useLanguage from '~/client/hooks/useLanguage';
import ActionIcons from './ActionIcons';
import GameBadge from './GameBadge';
import GameImageContainer from './GameImageContainer';
import GameProvider from './GameProvider';
import type GameThumbProps from './GameThumbProps';
import getWatermarkHref from './getWatermarkHref';
import useGameThumb from './hooks/useGameThumb';
import SmallThumb from './SmallThumb';
import CardBettingRangeDetail from './styles/CardBettingRangeDetail';
import CurrencyDisplay from '../../CurrencyDisplay';
import Img from '../../images/Img';

const Hover = dynamic(() => import('./Hover'));

const GameThumb: FC<GameThumbProps> = (props) => {
  const {
    game,
    thumb,
    isHoverEnabled,
    isFavouriteToggleVisible,
    preloadImg,
    isBadge = true,
    isMinMaxBetVisible = false,
    newThumbsEnabled,
    isCarousel,
  } = useGameThumb(props);
  const { isMobile } = useAgentData();
  const { locale } = useLanguage();
  const textAlign = locale === 'ar' ? 'right' : 'left';
  const isBetRange = isMinMaxBetVisible && !!game.minBet && !!game.maxBet;
  const mobileWidth = isBetRange ? 180 : 160;
  const newThumbsWidth = isMobile ? mobileWidth : 220;
  const newThumbsHeight = isMobile ? 212 : 280;
  if (!newThumbsEnabled) {
    return <SmallThumb {...props} />;
  }
  return (
    <div className="relative shrink-0 cursor-pointer rounded-moon-i-sm overflow-hidden flex justify-center max-w-[218px]">
      {isHoverEnabled && (
        <Hover game={game} gameHref={props.href ?? game.href ?? ''} />
      )}
      <GameProvider game={game} isBetRangeVisible={isBetRange} />
      <ActionIcons
        game={game}
        isFavouriteToggleVisible={isFavouriteToggleVisible}
      />
      {isBadge && <GameBadge game={game} />}
      <GameImageContainer
        {...props}
        className={mergeClassnames(
          '-mx-1 xxsm:-mx-2 sm:mx-0',
          isBetRange && '-mx-3 sm:-mx-1',
        )}
      >
        <Img
          preload={preloadImg}
          src={thumb ?? ''}
          width={newThumbsWidth}
          height={newThumbsHeight}
          alt={game.name ?? ''}
          useDpr2x
          fit="crop"
          crop="faces"
          mark={getWatermarkHref(
            game.name,
            isMobile,
            isCarousel,
            textAlign,
            isBetRange,
          )}
        />
        {isMinMaxBetVisible && game.minBet && game.maxBet && (
          <CardBettingRangeDetail>
            <CurrencyDisplay amount={game.minBet} isFormatted /> -
            <CurrencyDisplay amount={game.maxBet} isFormatted />
          </CardBettingRangeDetail>
        )}
      </GameImageContainer>
    </div>
  );
};

export default GameThumb;

import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import ThemeLinksSection from './ThemeLinksSection';
import useGameDetails from './useGameDetails';
import GameDetailsTable from '../GameDetailsTable';

type Props = { game: SingleGameFragment };

const GameDetailsTooltip: FC<Props> = ({ game }) => {
  const { hasData } = useGameDetails(game);
  if (!hasData) {
    return <div />;
  }
  return (
    <div className="grid gap-4">
      <GameDetailsTable game={game} hideTitle />
      <ThemeLinksSection themes={game?.themes?.edges} />
    </div>
  );
};

export default GameDetailsTooltip;

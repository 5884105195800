const normalizeSrcset = (srcset: string) =>
  srcset ? srcset.replace(/ /g, '%20') : srcset;

const applyDpr = (dpr: number, quality: number) => `&q=${quality}&dpr=${dpr}`;

const applyDprs = (url: string, params?: ImgixParams) => {
  if (!params) {
    return url;
  }
  const { useDpr2x, useDpr3x } = params;
  return getResult(url, useDpr2x, useDpr3x);
};

const getResult = (url: string, useDpr2x?: boolean, useDpr3x?: boolean) => {
  const result = `${normalizeSrcset(url)}`;
  if (useDpr2x) {
    return `${normalizeSrcset(url)}${applyDpr(2, 50)}`;
  }
  if (useDpr3x) {
    return `${normalizeSrcset(url)}${applyDpr(3, 33)}`;
  }
  return result;
};

export default applyDprs;

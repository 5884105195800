import { useContext } from 'react';
import ThemeContext from '~/client/containers/shared/ThemeProvider/ThemeContext';

const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useThemeContext should be used within the ThemeContext');
  }

  return context;
};

export default useThemeContext;

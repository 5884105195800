import parse from 'html-react-parser';
import replaceHref from './replaceHref';
import replaceImage from './replaceImage';

type Args = {
  content: string;
  isMobile?: boolean;
  isFixedImgSize?: boolean;
  isTablet?: boolean;
  domain?: string;
  setCashierOpen?: (isOpen: boolean) => void;
};

const parseContent = ({
  content,
  isFixedImgSize,
  isMobile,
  domain,
  isTablet,
  setCashierOpen,
}: Args) =>
  parse(content || '', {
    replace: (node) =>
      replaceContent({
        node: cast(node),
        domain,
        isFixedImgSize,
        isMobile,
        isTablet,
        setCashierOpen,
      }),
  });

const replaceContent = ({
  node,
  domain,
  isFixedImgSize,
  isMobile,
  isTablet,
  setCashierOpen,
}: Omit<Args, 'content'> & { node: CmsNode }) => {
  const { attribs, name } = node;
  if (attribs?.href) {
    return replaceHref({ node, domain, setCashierOpen });
  }
  if (name === 'img' && attribs?.src) {
    return replaceImage(attribs, isMobile, isFixedImgSize, isTablet);
  }
  return node;
};

const cast = (node: unknown) => node as CmsNode;

export default parseContent;

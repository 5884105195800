type Props = {
  id?: string;
};

const Section: FC<Props> = ({ children, id = 'page' }) => (
  <section data-testid={`${id}-section`} className="flex flex-col gap-5">
    {children}
  </section>
);

export default Section;

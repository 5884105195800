import useAppContext from '~/shared/context/AppContext/useAppContext';
import isExternalHref from '~/shared/utils/router/isExternalHref';
import useParseExternalHref from './useParseExternalHref';
import usePatchHrefLocale from './usePatchHrefLocale';
import type { LinkProps } from '..';

const useNextLink = (props: LinkProps) => {
  const { pathPrefix } = useAppContext();
  const { patchHrefLocale } = usePatchHrefLocale();
  const href = props.href;
  const { parseExternalHref } = useParseExternalHref();
  const parsedHref = parseExternalHref(href);
  const isExternal = isExternalHref(parsedHref);
  if (!pathPrefix) {
    return { href: patchHrefLocale(href), isExternal };
  }
  const newHref =
    isExternal || parsedHref.includes(pathPrefix)
      ? parsedHref
      : `${pathPrefix}${parsedHref}`;

  return { href: patchHrefLocale(newHref), isExternal };
};

export default useNextLink;

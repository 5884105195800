import { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import NextLink from '~/client/shared/NextLink';
import sendInteractionToGtm from '~/shared/gtm/sendInteractionToGtm';
import getPreparedHref from './getPreparedHref';

type Args = {
  node: CmsNode;
  options?: HTMLReactParserOptions;
  domain?: string;
  setCashierOpen?: (isOpen: boolean) => void;
};

const replaceHref = ({ node, domain, options, setCashierOpen }: Args) => {
  const { attribs, children } = node;
  const href = attribs?.href ?? '';
  if (href) {
    const { preparedHref } = getPreparedHref(href);
    const preparedURL = new URL(`https://${domain}${preparedHref}`);
    const current = global?.location?.pathname;
    const isSamePathname =
      current === preparedURL.pathname?.replace(/\/+$/, '');
    const cashierHref = preparedURL.searchParams.get('c');

    const handleClick = () => {
      if (cashierHref) {
        setCashierOpen?.(true);
      }
      sendToGtm(attribs?.href);
    };

    if (cashierHref && isSamePathname) {
      return (
        <div className={attribs?.class} onClick={handleClick}>
          {domToReact(children, options)}
        </div>
      );
    }
    return (
      <NextLink
        onClick={handleClick}
        href={preparedHref}
        className={attribs?.class}
      >
        <>{domToReact(children, options)}</>
      </NextLink>
    );
  }
  return node as unknown as JSX.Element;
};

const sendToGtm = (href?: string) => {
  sendInteractionToGtm({
    eventCategory: 'cms_block_link',
    eventAction: 'cms_block',
    eventInfo: href,
  });
};

export default replaceHref;

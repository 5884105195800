import useCashierLink from '~/client/hooks/feature/useCashierLink';
import Button from '~/client/shared/Button';

type Props = { text: string; cashierPath?: string };

const DepositButtonDesktop: FC<Props> = ({ text, cashierPath }) => {
  const { open } = useCashierLink({ cashierPath });
  return (
    <Button variant="primary" onClick={open} data-testid="deposit-button">
      {text}
    </Button>
  );
};

export default DepositButtonDesktop;

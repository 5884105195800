import isTrustedCdnDomain from '~/shared/cdn/isTrustedCdnDomain';
import {
  CASINO_CDN,
  HUB88_CDN,
  HUB88_IMGIX,
  CASINO_IMGIX,
} from '~/shared/cdn/url';

const IMG_EXTENSIONS = ['png', 'jpg', 'jpeg', 'JPG', 'svg'];
const CASINO_CDN_REGEX = new RegExp(CASINO_CDN, 'g');
const HUB88_CDN_REGEX = new RegExp(HUB88_CDN, 'g');

const applyImgix = (params: ImgixParams) => {
  const { src = '', cdnDomain } = params;
  if (!isTrustedCdnDomain(src)) {
    return src;
  }
  const imgixSrc = replaceWithImgixDomain(src);
  const imgixParams = applyImgixParams(params);
  const result = IMG_EXTENSIONS.reduce(
    (acc, ext) => acc.replaceAll(`.${ext}`, `.${ext}${imgixParams}`),
    replaceImgixNginxProxy(imgixSrc, cdnDomain),
  );
  return result;
};

const applyImgixParams = (params: ImgixParams) => {
  const pinned = '?auto=compress,format';
  const w = applyImgixParam('w', params.w);
  const h = applyImgixParam('h', params.h);
  const mono = applyImgixParam('mono', params.mono);
  const blur = applyImgixParam('blur', params.blur);
  const mask = applyImgixParam('mask', params.mask);
  const fit = applyImgixParam('fit', params.fit);
  const ar = applyImgixParam('ar', params.ar);
  const crop = applyImgixParam('crop', params.crop);
  const mark = applyImgixParam('mark', params.mark);
  const markPad = params.mark ? applyImgixParam('mark-pad', '0') : '';
  const markScale = params.mark ? applyImgixParam('mark-scale', '100') : '';
  return `${pinned}${w}${h}${mono}${blur}${mask}${fit}${ar}${crop}${mark}${markPad}${markScale}`;
};

const applyImgixParam = (param: string, value?: string | number) => {
  if (!value) {
    return '';
  }
  return `&${param}=${value}`;
};

const replaceWithImgixDomain = (input: string) =>
  input
    .replace(CASINO_CDN_REGEX, CASINO_IMGIX)
    .replace(HUB88_CDN_REGEX, HUB88_IMGIX);

const replaceImgixNginxProxy = (input: string, cdnDomain?: string) => {
  if (!cdnDomain) {
    return input;
  }
  return input.replace(
    new RegExp(`(https?:)?//${CASINO_IMGIX}`, 'g'),
    `${cdnDomain}/imgix`,
  );
};

export default applyImgix;

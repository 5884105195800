import {
  CASINO_CDN,
  CASINO_IMGIX,
  HUB88_CDN,
  HUB88_IMGIX,
  SB_IMGIX_DOMAIN,
} from '~/shared/cdn/url';
import includesAny from '~/shared/utils/string/includesAny';

const isTrustedCdnDomain = (src = '') =>
  includesAny(src, [
    HUB88_CDN,
    HUB88_IMGIX,
    CASINO_CDN,
    CASINO_IMGIX,
    SB_IMGIX_DOMAIN,
  ]);

export default isTrustedCdnDomain;

import { mergeClassnames } from '@heathmont/moon-core-tw';

type Props = {
  className?: string;
};

const GameDetailsInline: FC<Props> = ({ children, className }) => (
  <div
    className={mergeClassnames(
      'grid grid-flow-col items-center justify-between gap-2 rounded-moon-s-sm bg-gohan px-3 py-2 md:min-w-[18.75rem]',
      className,
    )}
  >
    {children}
  </div>
);

export default GameDetailsInline;

import { useMemo, useState } from 'react';
import { useToast } from '@heathmont/moon-notifications';
import { rem } from '@heathmont/moon-utils';
import styled from 'styled-components';
import useIsRtl from '~/client/hooks/useIsRtl';
import Translate from '~/client/shared/i18n/Translate';
import LazyInView from '~/client/shared/LazyLoad/Lazy/LazyInView';
import ToastContext from './ToastContext';

const ToasterContainer = styled.div<{ isRtl: boolean }>`
  direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
  & > div:first-child {
    right: ${({ isRtl }) => (isRtl ? rem(0) : 'unset')};
  }
`;

const ToastContextProvider: FC = ({ children }) => {
  const { isRtl } = useIsRtl();
  const [toastParams, setToastParams] = useState<ToastProps>({
    message: '',
    variant: 'success',
    position: 'bottom',
    timeout: 5000,
  });

  const [Toast, displayToast] = useToast({
    message: (
      <Translate
        id={toastParams.message}
        values={toastParams?.translateValues}
      />
    ),
    timeout: toastParams.timeout,
    variant: toastParams.variant,
    position: toastParams.position,
  });

  const values = useMemo(
    () => ({
      toastParams,
      setToastParams,
      displayToast,
    }),
    [displayToast, toastParams],
  );

  return (
    <ToastContext.Provider value={values}>
      <LazyInView>
        <ToasterContainer isRtl={isRtl}>{Toast}</ToasterContainer>
      </LazyInView>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;

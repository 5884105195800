import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import isValidMinMaxBet from '~/shared/utils/game/isValidMinMaxBet';
import isValidVolatility from '~/shared/utils/game/isValidVolatility';

const hasAnyData = (
  game: SingleGameFragment,
  currencyCode: CashierCurrency | BtcUnit,
) => {
  const { minBet, maxBet, rtp, volatility, payLines, hitRatio } = game;
  return (
    ((minBet || maxBet) && isValidMinMaxBet(currencyCode)) ||
    rtp ||
    isValidVolatility(volatility) ||
    payLines ||
    hitRatio
  );
};

export default hasAnyData;

import dynamic from 'next/dynamic';
import useFeature from '~/client/hooks/feature/useFeature';

const LiveRtpHome = dynamic(() => import('./LiveRtpHome'));

const LiveRtp: FC<LiveRtpProps> = (props) => {
  const isLiveRtpEnabled = useFeature('rtpLive');
  if (!isLiveRtpEnabled) {
    return null;
  }
  return <LiveRtpHome {...props} />;
};

export default LiveRtp;

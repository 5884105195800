import type { GameThemeNodeFragment } from '~/client/shared/graphql/GameFragment.generated';
import ThemeLink from './ThemeLink';

type Props = {
  themes?: GameThemeNodeFragment[];
};

const ThemeLinksSection: FC<Props> = ({ themes }) => {
  if (!themes || themes.length === 0) {
    return null;
  }
  const themeLinks = getThemeLinks(themes);
  return <div className="flex max-w-xs flex-row flex-wrap">{themeLinks}</div>;
};

const getThemeLinks = (themes: GameThemeNodeFragment[]) =>
  themes.map(({ node }) => (
    <ThemeLink key={node?.id} handle={node?.handle ?? ''} />
  ));

export default ThemeLinksSection;

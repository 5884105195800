import { SyntheticEvent, forwardRef } from 'react';
import { mergeClassnames } from '@heathmont/moon-core-tw';

type Props = {
  loggedIn?: boolean;
  promotionReady?: boolean;
  isArticle?: boolean;
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
};

const CmsContainer: FC = forwardRef<HTMLInputElement, Props>(
  (
    { children, promotionReady, loggedIn, isArticle, className, onClick },
    ref,
  ) => (
    <div
      ref={ref}
      className={mergeClassnames(
        'text-trunks',
        '[&_iframe]:max-w-full [&_table]:text-base [&_table]:max-w-full [&_table]:block',
        '[&_img]:inline [&_p]:my-4 [&_p]:text-base',
        '[&_ol]:list-decimal [&_ol]:text-base [&_ol]:[margin-block:1rem] [&_ol]:[margin-inline:0] [&_ol]:[padding-inline-start:2.5rem]',
        '[&_ul]:list-disc [&_ul]:text-base [&_ul]:[margin-block:1rem] [&_ul]:[margin-inline:0] [&_ul]:[padding-inline-start:2.5rem]',
        !promotionReady && '[&_[data-promotion-name]]:hidden',
        loggedIn
          ? '[&_.pt-button.cms_visibility_logged_in]:hidden'
          : '[&_.pt-button.cms_visibility_logged_out]:hidden',
        isArticle && '[&_a_span]:!text-piccolo',
        className && className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  ),
);

export default CmsContainer;

import stripLocaleFromPath from '~/shared/utils/router/stripLocaleFromPath';

const DOMAIN_REGEX = /https?:\/\/bitcasino.io\/?/i;

const getPreparedHref = (href: string) => {
  const domainFreeHref = stripDomainFromHref(replaceWww(href));
  if (isExternal(domainFreeHref)) {
    return { preparedHref: domainFreeHref, target: '_blank' };
  }
  const languageFreeHref = stripLocaleFromPath(domainFreeHref);
  const fixedHref = fixLegacyLinks(languageFreeHref);
  const normalisedHref = normaliseHref(fixedHref);
  const preparedHref = stripSlashFunFromHref(normalisedHref);
  return { preparedHref: addSlashPrefix(preparedHref), target: '_self' };
};

const fixLegacyLinks = (href: string) =>
  href
    .replace('#bonuses', 'profile/rewards')
    .replace('#funds', 'profile/funds')
    .replace('#deposit', 'profile/deposit')
    .replace('#withdraw', 'profile/withdraw')
    .replace('#moonpay-buy', 'profile/buy-crypto')
    .replace('#transactions', 'profile/transactions')
    .replace('#loyalty', 'profile/loyalty')
    .replace('#tournaments', 'profile/tournaments')
    .replace('#account', 'profile/account')
    .replace('#security', 'profile/security')
    .replace('#settings', 'profile/settings')
    .replace('#notifications', 'profile/notifications')
    .replace('#boosts', 'profile/boosts');

const replaceWww = (href: string) =>
  href.startsWith('www.') ? href.replace('www.', 'https://') : href;

const stripDomainFromHref = (href: string) => href.replace(DOMAIN_REGEX, '/');

const normaliseHref = (href: string) => {
  const hashRegex = /^\/#/i;
  return href.replace(hashRegex, '/').trim();
};

const isExternal = (href: string) =>
  href.startsWith('http') || href.startsWith('mailto:');

const stripSlashFunFromHref = (href: string) => {
  if (href.endsWith('/fun')) {
    return href.slice(0, -4);
  }
  return href;
};

const addSlashPrefix = (href: string) =>
  href?.startsWith('/') ? href : `/${href}`;

export default getPreparedHref;

/* Required for changing CMS buttons */
/* eslint-disable immutable/no-mutation */
import { SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import useTranslation from '~/client/hooks/i18n/useTranslation';
import useDidMount from '~/client/hooks/lifecycle/useDidMount';
import useRouter from '~/client/hooks/router/useRouter';
import useCurrentUser from '~/client/hooks/user/useCurrentUser';
import type CmsViewProps from '~/client/shared/cms/CmsContainer/CmsView/CmsViewProps';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import sendPromotionClickToGtm from '~/shared/gtm/sendPromotionClickToGtm';
import useCookie from '~/shared/hooks/useCookie';
import sendEventToUmami from '~/shared/trackEvent/sendEventToUmami';

const useCmsView = ({
  optInOnInit,
  contentOptIn,
  onClick,
  publishEndDate,
  handle,
}: CmsViewProps) => {
  const router = useRouter();
  const { pathPrefix } = useAppContext();
  const { user } = useCurrentUser();
  const btnLoadingText = useTranslation('general.button.loading');
  const btnOopsText = useTranslation('general.button.oops');
  const btnParticipatingText = useTranslation('general.button.participating');
  const node = useRef<HTMLDivElement>(null);
  const [, setCookie] = useCookie('promotion-on-top-closed');

  const [, setNotificationCookie] = useCookie<string>('optin-notification');

  const toggleOptIn = useCallback(() => {
    if (!contentOptIn) {
      return;
    }
    if (user) {
      const buttons = getActionButtons('opt-in');
      buttons?.forEach((button) => {
        button.innerHTML = btnLoadingText;
        button.disabled = true;
      });

      contentOptIn().then((res) => {
        if (res?.data?.contentOptIn?.errors !== null) {
          buttons?.forEach((button) => {
            button.innerHTML = btnOopsText;
          });
        } else {
          buttons?.forEach((button) => {
            button.innerHTML = btnParticipatingText;
          });
        }
      });
    }
  }, [btnLoadingText, btnOopsText, btnParticipatingText, contentOptIn, user]);

  useEffect(() => {
    if (optInOnInit) {
      toggleOptIn();
    }
  }, [optInOnInit, toggleOptIn]);

  const getActionButtons = (action: string) =>
    node.current?.querySelectorAll<HTMLButtonElement>(
      `[data-action="${action}"]`,
    );

  const markInteractiveElementsForGtm = () => {
    node.current?.querySelectorAll('a').forEach((el) => {
      el.setAttribute('data-gtm-event-element', 'true');
      el.setAttribute('data-gtm-event-category', 'cms_block_link');
      el.setAttribute('data-gtm-event-location', 'cms_block');
      const href = el.getAttribute('href');
      if (href?.includes('/promotions/')) {
        el.setAttribute('data-gtm-promotion-link', 'true');
      }
    });
  };

  const closePromotionOnTop = () => {
    if (publishEndDate) {
      setCookie('1', {
        expires: new Date(publishEndDate),
      });
    } else {
      setCookie('1');
    }
  };

  useDidMount(markInteractiveElementsForGtm);

  const isRedirectToExternalPage = (href: string) =>
    !href.startsWith(pathPrefix);

  const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
    const dataHref =
      e?.target instanceof HTMLButtonElement && e?.target?.dataset.href;

    const href = e?.currentTarget?.getAttribute('href') ?? '';
    if (isRedirectToExternalPage(href)) {
      return;
    }
    if (href.startsWith('/')) {
      e.preventDefault();
      if (href.includes('/promotions/')) {
        handleGTM(href);
      }
      router.push(href);
    }

    // Opt in button handling
    if (
      e?.target instanceof HTMLButtonElement &&
      e?.target?.dataset.action === 'opt-in'
    ) {
      sendEventToUmami(
        {
          handle,
        },
        'optIn',
      );

      if (isLoggedIn) {
        setNotificationCookie('1');
        toggleOptIn();
        router.push(`${dataHref}`);
      } else if (!router.asPath.includes('login')) {
        const redirectPromo = router?.asPath;
        router.push(`/login?redirect=${redirectPromo}`);
      }
    }

    if (
      (e?.target instanceof HTMLButtonElement ||
        e?.target instanceof HTMLImageElement) &&
      e.target.dataset.action === 'close-promotion-on-top'
    ) {
      closePromotionOnTop();
    }

    onClick?.(e);
  };

  const isLoggedIn = !!user;

  return { node, isLoggedIn, handleClick };
};

const handleGTM = (href: string) => {
  const handle = href.split('/promotions/')[1];
  sendPromotionClickToGtm({
    handle,
    position: 'cmsblock',
  });
};

export default useCmsView;

import replaceVariables from './replaceVariables';
import useTranslationCollector from './useTranslationCollector';
import useAppContext from '../../../shared/context/AppContext/useAppContext';
import useSite from '../useSite';

const useTranslation = (
  id: string,
  { values = {}, defaultTo = '' }: TranslateOptions = {},
) => {
  const { siteConfig } = useSite();
  useTranslationCollector(id);
  useTranslationCollector(defaultTo);
  const { translations } = useAppContext();
  const translation = translations[id];
  const alternate = translations[defaultTo];

  if (!translation && !alternate) {
    return id;
  }

  return replaceVariables(translation || alternate, {
    siteName: siteConfig.name,
    siteDomain: siteConfig.domain,
    ...values,
  });
};

export default useTranslation;

const getWatermarkHref = (
  gameName = '',
  isMobile = false,
  isCarousel = false,
  textAlign = 'left',
  isBetRange = false,
) => {
  const textSize = getNameTextSize(gameName);
  const text = new URL('https://assets.imgix.net/~text');
  const textLead = textSize / 2;
  text.searchParams.set('txt', gameName.toUpperCase());
  text.searchParams.set('txt-align', textAlign);
  text.searchParams.set('txt-font', 'AvenirNextCondensed-Bold');
  text.searchParams.set('txt-color', 'white');
  text.searchParams.set('txt-size', textSize.toString());
  text.searchParams.set('txt-lead', `-${textLead.toString()}`);
  text.searchParams.set('txt-pad', '0');
  text.searchParams.set('dpr', '3');
  const watermark = new URL('https://heathmont.imgix.net/images/gradient3.png');
  watermark.searchParams.set('mark-align', 'center,bottom');
  watermark.searchParams.set('mark-scale', '100');
  const mobilePadding = !isCarousel || isBetRange ? '55' : '40';
  const desktopPadding = isBetRange ? '40' : '35';
  watermark.searchParams.set(
    'mark-pad',
    isMobile ? mobilePadding : desktopPadding,
  );
  watermark.searchParams.set('mark', text.href);
  watermark.searchParams.set('dpr', '3');
  return encodeURIComponent(watermark.href);
};

const getNameTextSize = (name = '') => {
  const wordsCount = name.split(' ').length;
  const endsWithSpaceAndSingleLetter = /\s\w$/.test(name);
  if (name.length <= 7 && wordsCount === 1) {
    return 44;
  }
  if (name.length <= 7) {
    return 40;
  }
  if (name.length <= 10 && wordsCount === 2 && !endsWithSpaceAndSingleLetter) {
    return 44;
  }
  if (name.length <= 12 && wordsCount > 2 && endsWithSpaceAndSingleLetter) {
    return 48;
  }
  if (name.length <= 13 && wordsCount === 2 && !endsWithSpaceAndSingleLetter) {
    return 38;
  }
  if (name.length <= 14 && wordsCount === 3 && endsWithSpaceAndSingleLetter) {
    return 40;
  }
  if (name.length <= 15 && wordsCount === 2 && !endsWithSpaceAndSingleLetter) {
    return 40;
  }
  if (name.length <= 15 && wordsCount === 3 && !endsWithSpaceAndSingleLetter) {
    return 38;
  }
  if (name.length <= 15 && wordsCount === 1) {
    return 35;
  }
  if (name.length <= 20) {
    return 32;
  }
  if (name.length <= 25) {
    return 28;
  }
  if (wordsCount === 3) {
    return 30;
  }
  if (wordsCount > 3) {
    return 24;
  }
  return 26;
};

export default getWatermarkHref;

import applyDprs from './applyDprs';
import applyImgix from './applyImgix';

const applyImgixToUrl = (params: ImgixParams) => {
  const { src } = params;
  if (!src) {
    return '';
  }
  const imgixUrl = applyImgix(params);
  const { useDpr2x, useDpr3x } = params;
  const useDpr = useDpr2x || useDpr3x;
  if (useDpr) {
    return applyDprs(imgixUrl, params);
  }
  return imgixUrl;
};

export default applyImgixToUrl;

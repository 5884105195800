/* eslint-disable no-restricted-imports */
import { forwardRef } from 'react';
import Link from 'next/link';
import useNextLink from './useNextLink';

const NextLink: typeof Link = forwardRef((linkProps, ref) => {
  const { href, isExternal } = useNextLink(linkProps);
  if (isExternal) {
    return (
      <a className={linkProps.className} href={href.toString()} ref={ref}>
        {linkProps.children}
      </a>
    );
  }
  return <Link {...linkProps} href={href} ref={ref} locale={false} />;
});

// eslint-disable-next-line import/no-named-export
export type { LinkProps } from 'next/link';
export default NextLink;

import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import Translate from '~/client/shared/i18n/Translate';
import GameDetailsRow from './shared/GameDetailsRow';

type Props = Pick<SingleGameFragment, 'inLoyalty'>;

const InLoyaltyRow: FC<Props> = ({ inLoyalty }) => {
  if (inLoyalty) {
    return null;
  }
  return (
    <GameDetailsRow translateId="loyalty.loyalty_system">
      <Translate id="casino.not_included" />
    </GameDetailsRow>
  );
};

export default InLoyaltyRow;

import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import useCurrencyCode from '~/client/hooks/currency/useCurrencyCode';
import CurrencyDisplay from '~/client/shared/CurrencyDisplay';
import isValidMinMaxBet from '~/shared/utils/game/isValidMinMaxBet';
import GameDetailsRow from './shared/GameDetailsRow';

type Props = Pick<SingleGameFragment, 'minBet' | 'maxBet'>;

const MinMaxBetRow: FC<Props> = ({ minBet, maxBet }) => {
  const currencyCode = useCurrencyCode();
  if (!isValidMinMaxBet(currencyCode)) {
    return null;
  }
  if (minBet && !maxBet) {
    return (
      <GameDetailsRow translateId="casino.min_bet">
        <CurrencyDisplay amount={minBet} isFormatted />
      </GameDetailsRow>
    );
  }
  if (!minBet && maxBet) {
    return (
      <GameDetailsRow translateId="casino.max_bet">
        <CurrencyDisplay amount={maxBet} isFormatted />
      </GameDetailsRow>
    );
  }
  if (minBet && maxBet) {
    return (
      <GameDetailsRow translateId="casino.min_max_bet">
        <CurrencyDisplay amount={minBet} amountOnly />
        {` - `}
        <CurrencyDisplay amount={maxBet} isFormatted />
      </GameDetailsRow>
    );
  }
  return null;
};

export default MinMaxBetRow;

import useAgentData from '~/client/hooks/useAgentData';
import Placeholder from '~/client/shared/LazyLoad/Placeholder';

const BannerPlaceholder = () => {
  const { isMobile } = useAgentData();
  const height = isMobile ? 277 : 94;
  return <Placeholder height={height} />;
};

export default BannerPlaceholder;

/* eslint-disable import/no-named-export */

export const CASINO_CDN = 'cdn.coingaming.io';
export const CASINO_IMGIX = 'heathmont.imgix.net';

export const HUB88_CDN = 'cdn.hub88.io';
export const HUB88_IMGIX = 'hub88.imgix.net';

export const SB_IMGIX_DOMAIN = 'sportsbet.imgix.net';

export const CDN_URL = `//${CASINO_CDN}`;
export const CDN_IMG_URL = `${CDN_URL}/bitcasino/images`;

import dynamic from 'next/dynamic';
import useFeature from '~/client/hooks/feature/useFeature';
import useIsLoggedIn from '~/client/hooks/user/useIsLoggedIn';
import BannerPlaceholder from './Banner/shared/BannerPlaceholder';

type Props = { module: CasinoBoostModule };

const Banner = dynamic(() => import('./Banner'), {
  ssr: false,
  loading: BannerPlaceholder,
});
const Notification = dynamic(() => import('./Notification'), { ssr: false });
const Settings = dynamic(() => import('./Settings'));

const CasinoBoost: FC<Props> = ({ module }) => {
  const { isVisible } = useCasinoBoost();
  if (!isVisible) {
    return null;
  }
  switch (module) {
    case 'game':
      return <Banner page="game" />;
    case 'home':
      return <Banner page="home" />;
    case 'notification':
      return <Notification />;
    case 'settings':
      return <Settings />;
    default:
      return null;
  }
};

const useCasinoBoost = () => {
  const isCasinoBoostsEnabled = useFeature('isCasinoBoostsEnabled');
  const isLoggedIn = useIsLoggedIn();
  const isVisible = isCasinoBoostsEnabled && isLoggedIn;
  return { isVisible };
};

export default CasinoBoost;

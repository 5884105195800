import trackEvent from '../trackEvent';

type Props = { handle: string; position: string };

const sendPromotionClickToGtm = ({ handle, position }: Props) =>
  trackEvent(
    {
      ecommerce: {
        promoClick: {
          promotions: [
            {
              position,
              id: handle,
              name: handle,
              creative: handle,
            },
          ],
        },
      },
    },
    'promotionClick',
  );

export default sendPromotionClickToGtm;

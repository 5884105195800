import isPathRequestFiltered from './isPathRequestFiltered';

const sendToGtm = (item: Record<string, unknown>) => {
  if (isPathRequestFiltered(item?.pageUrl as string)) {
    return;
  }

  const dataLayer = global?.dataLayer;
  if (!dataLayer) {
    return;
  }
  dataLayer.push({ ...item, source: 'next-casino' });
};

export default sendToGtm;

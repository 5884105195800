import trackEvent from '../trackEvent';

type Args = {
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventInfo?: string;
};

const sendInteractionToGtm = ({
  eventCategory,
  eventAction,
  eventLabel,
  eventInfo = '',
}: Args) =>
  trackEvent(
    {
      eventCategory,
      eventAction,
      eventLabel,
      eventInfo,
    },
    'elementInteraction',
  );

export default sendInteractionToGtm;

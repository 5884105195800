import useAgentData from '~/client/hooks/useAgentData';
import LazyView from './Lazy';

type Props = {
  minHeight?: number | string;
  gridArea?: string;
  placeholder?: JSX.Element;
};

const Lazy: FC<Props> = ({ minHeight, gridArea, placeholder, children }) => {
  const { isCrawler } = useAgentData();
  if (isCrawler) {
    return <>{children}</>;
  }
  return (
    <LazyView
      minHeight={minHeight}
      gridArea={gridArea}
      placeholder={placeholder}
    >
      {children}
    </LazyView>
  );
};

export default Lazy;

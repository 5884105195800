'use client';
import { PrefetchKind } from 'next/dist/client/components/router-reducer/router-reducer-types';
import { resolveHref } from 'next/dist/client/resolve-href';
import { Url } from 'next/dist/shared/lib/router/router';
import { RouterContext } from 'next/dist/shared/lib/router-context.shared-runtime';
import {
  useRouter,
  usePathname,
  useSearchParams,
  useParams,
} from 'next/navigation';
import { Router } from 'next/router';

const RouterContextProvider: FC = ({ children }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const params = useParams();
  const query = {
    ...params,
    ...Object.fromEntries(searchParams?.entries() ?? []),
  };

  const pathname = usePathname() || '';
  return (
    <RouterContext.Provider
      value={{
        pathname,
        query,
        basePath: '',
        route: pathname,
        asPath: pathname,
        isLocaleDomain: false,
        push: async (url, _as, options) => {
          router.push(resolveUrl(url), options);
          return true;
        },
        replace: async (url, _as, options) => {
          router.replace(resolveUrl(url), options);
          return true;
        },
        prefetch: async (url, _as, options) => {
          router.prefetch(resolveUrl(url), {
            ...options,
            kind: PrefetchKind.AUTO,
          });
        },
        reload: router.refresh,
        back: router.back,
        forward: router.forward,
        beforePopState: () => undefined,
        events: {
          // TODO throw error if used on app router
          on: () => {},
          off: () => {},
          emit: () => {},
        },
        isFallback: false,
        isReady: true,
        isPreview: true,
      }}
    >
      {children}
    </RouterContext.Provider>
  );
};

const resolveUrl = (url: Url) => {
  if (typeof url === 'string') {
    return url;
  }
  return resolveHref({} as Router, url);
};

export default RouterContextProvider;

import useTranslation from '~/client/hooks/i18n/useTranslation';
import Button from '~/client/shared/Button';

type Props = {
  isCollapsed: boolean;
  setCollapsed: (value: boolean) => void;
};

const ShowMoreToggle: FC<Props> = ({ isCollapsed, setCollapsed }) => {
  const showMore = useTranslation('general.show_more');
  const showLess = useTranslation('general.show_less');
  return (
    <div className="flex justify-center pt-4">
      <Button
        className="font-normal"
        variant="tertiary"
        onClick={() => setCollapsed(!isCollapsed)}
      >
        {isCollapsed ? showMore : showLess}
      </Button>
    </div>
  );
};

export default ShowMoreToggle;

import EagerImg from './EagerImg';
import LazyImg from './LazyImg';
import getImgixSrc from '../imgix/getImgixSrc';

const Img: FC<ImgProps> = (props) => {
  const src = getImgixSrc(props);
  if (props.preload) {
    return <EagerImg {...props} src={src} />;
  }
  return <LazyImg {...props} src={src} />;
};

export default Img;

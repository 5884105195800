import { useState } from 'react';
import type { CasinoGameConnectionSorter } from '~/schema/public';
import type SortButtonProps from '../SortButtonProps';

type Args = Pick<SortButtonProps, 'onChange'>;

const useListBoxState = ({ onChange }: Args) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (newValue: keyof CasinoGameConnectionSorter) => {
    onChange(newValue);
    setIsOpen(false);
  };
  const handleClick = () => setIsOpen(!isOpen);
  return { isOpen, handleChange, handleClick };
};

export default useListBoxState;

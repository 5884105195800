import { MediaTuner } from '@heathmont/moon-icons';
import { rem } from '@heathmont/moon-utils';
import Translate from '~/client/shared/i18n/Translate';
import LockBodyScroll from '~/client/shared/LockBodyScroll';
import ListBox from '../shared/ListBox';
import useListBoxState from '../shared/useListBoxState';
import type SortButtonProps from '../SortButtonProps';

const MobileSortButton: FC<SortButtonProps> = ({
  options,
  selectedValue,
  onChange,
}) => {
  const { isOpen, handleClick, handleChange } = useListBoxState({ onChange });
  return (
    <>
      <button
        className="flex h-8 w-8 cursor-pointer items-center justify-center border-0 bg-transparent text-trunks"
        onClick={handleClick}
        data-testid="mobile-sort-button"
      >
        <MediaTuner fontSize={rem(24)} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-10000">
          <LockBodyScroll />
          <div
            className="fixed inset-0 z-10 bg-[rgba(0,0,0,0.56)]"
            onClick={handleClick}
            data-testid="mobile-sort-overlay"
          />
          <div className="fixed bottom-[60px] left-0 right-0 z-20 rounded-tl-xl rounded-tr-xl bg-gohan shadow">
            <header className="px-4 pt-4 text-sm text-trunks">
              <Translate id="general.sort-games-by" />
            </header>
            {isOpen && (
              <ListBox
                options={options}
                selectedValue={selectedValue}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default MobileSortButton;

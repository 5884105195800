import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import { useCmsPageQuery } from '~/shared/graphql/content/CmsPageQuery.generated';
import getCmsPageVariables from '~/shared/graphql/content/helpers/getCmsPageVariables';

type Args = {
  handle?: string;
  section?: string;
  optedIn?: boolean;
  showPublished?: boolean;
};

const useCmsPage = ({ handle, section, optedIn, showPublished }: Args) => {
  const { site } = useSite();
  const { code: language } = useLanguage();
  const variables = getCmsPageVariables({
    handle,
    section,
    optedIn,
    showPublished,
    language,
    site,
  });
  const { loading, error, data } = useCmsPageQuery({ variables });
  return { loading, error, data };
};

export default useCmsPage;

const includesAny = (valueChecked: string, values: string[]) => {
  for (const value of values) {
    if (valueChecked.includes(value)) {
      return true;
    }
  }
  return false;
};

export default includesAny;

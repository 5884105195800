import ListBoxItem from './ListBoxItem';
import type SortButtonProps from '../../SortButtonProps';

const ListBox: FC<SortButtonProps> = ({ options, selectedValue, onChange }) => (
  <div
    className="z-10000 flex min-w-[168px] list-none flex-col rounded-xl bg-gohan p-2 shadow md:absolute md:right-0 md:mt-0"
    data-testid="sort-button-listbox"
  >
    {options.map((option) => (
      <ListBoxItem
        key={option.value}
        isSelected={option.value === selectedValue}
        onChange={onChange}
        value={option.value}
        label={option.label}
      />
    ))}
  </div>
);

export default ListBox;

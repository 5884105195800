/* eslint-disable immutable/no-mutation */
/* eslint-disable no-restricted-imports */
import { Context, useContext } from 'react';
import { NextRouter, Url } from 'next/dist/shared/lib/router/router';
import { RouterContext } from 'next/dist/shared/lib/router-context.shared-runtime';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import useSite from '../useSite';

const useRouter = () => {
  const router = useContext(RouterContext as Context<NextRouter>);
  const { siteConfig, isBridgeSite } = useSite();
  const { params, language } = useAppContext();
  const query = router.query;
  delete query.lang;
  if (isBridgeSite) {
    Object.assign(query, params);
  }
  const { defaultLang } = siteConfig;
  const asPath =
    language === defaultLang
      ? router.asPath
      : router.asPath.replace(`/${language}`, '');
  const { pathname } = new URL(asPath, `https://${siteConfig.domain}`);
  const push: typeof router.push = (url, as, options) => {
    const fixedUrl = fixUrlLocale(url, language, defaultLang, asPath);
    return router.push(fixedUrl, as, options);
  };
  const replace: typeof router.replace = (url, as, options) => {
    const fixedUrl = fixUrlLocale(url, language, defaultLang, asPath);
    return router.replace(fixedUrl, as, options);
  };
  return {
    ...router,
    push,
    replace,
    params,
    query,
    language,
    asPath: pathname,
    locale: language,
    defaultLocale: defaultLang,
  };
};

const fixUrlLocale = (
  url: Url,
  language: string,
  defaultLang: string,
  pathname: string,
) => {
  const languagePath = `/${language}`;
  if (typeof url === 'string' && language !== defaultLang) {
    if (url.startsWith(languagePath)) {
      return url;
    }
    return `${languagePath}${url}`;
  }
  if (url instanceof Object && language !== defaultLang) {
    url.pathname = url.pathname ?? pathname;
    if (url.pathname.startsWith(languagePath)) {
      return url;
    }
    url.pathname = `${languagePath}${url.pathname}`;
  }
  return url;
};

export default useRouter;

import LiveRtp from '~/client/features/LiveRtp';
import useNewThumbnailsEnabled from '~/client/hooks/feature/useNewThumbnailsEnabled';
import GameThumb from '~/client/shared/game/GameThumb';
import trackEvent from '~/shared/trackEvent';
import GameInfo from './GameInfo';
import GameCardTemplate from './template/GameCardTemplate';
import useGameCardSize from './useGameCardSize';
import saveEventData from '@/client/helpers/trackEvent/umamiEvents/saveEventData';
import type SourceGameSliderType from '@/client/helpers/trackEvent/umamiEvents/types/SourceGameSliderType';

type Props = {
  game: GameFragment;
  big?: boolean;
  light?: boolean;
  isLiveRtp?: boolean;
  rtpField?: RtpField;
  tournamentGame?: boolean;
  href?: string;
  preloadImg?: boolean;
  showGrid?: boolean;
  isMinMaxBetVisible?: boolean;
  isCarousel?: boolean;
  sourcePage?: string;
  sliderName?: SourceGameSliderType;
  sliderTileIndex?: number;
  gridIndex?: number;
  page?: number;
};

const GameCard: FC<Props> = ({
  game,
  big,
  light,
  rtpField,
  tournamentGame,
  href,
  preloadImg,
  showGrid,
  isMinMaxBetVisible,
  isCarousel,
  sourcePage,
  sliderName,
  gridIndex,
  sliderTileIndex,
  page,
}) => {
  const newThumbsEnabled = useNewThumbnailsEnabled();
  const { width, height } = useGameCardSize(big, tournamentGame);

  const handleClick = () => {
    if (sourcePage) {
      const el = document.querySelector('#default-layout-body');

      saveEventData({
        event: 'launchGame',
        page: sourcePage,
        slider:
          sliderName && sliderTileIndex
            ? {
                name: sliderName,
                sliderTileIndex,
                screenVPos: el?.scrollTop || 0,
              }
            : undefined,
        grid:
          gridIndex && page
            ? {
                index: gridIndex,
                page,
              }
            : undefined,
      });
    }

    trackEvent({ gameName: game?.name }, 'navigateToGame');
  };

  return (
    <GameCardTemplate width={width} showGrid={showGrid} onClick={handleClick}>
      <GameThumb
        game={game}
        width={width}
        showGrid={showGrid}
        height={height}
        isHoverEnabled
        href={href}
        big={big}
        light={light}
        preloadImg={preloadImg}
        isMinMaxBetVisible={isMinMaxBetVisible}
        isCarousel={isCarousel}
      />
      {!newThumbsEnabled && (
        <div className="grid gap-1">
          <GameInfo game={game} href={href} />
          {rtpField && (
            <LiveRtp module="badges" game={game} rtpField={rtpField} />
          )}
        </div>
      )}
    </GameCardTemplate>
  );
};

export default GameCard;

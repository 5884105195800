import { resolveHref } from 'next/dist/client/resolve-href';
import { Router } from 'next/router';
import useRouter from '~/client/hooks/router/useRouter';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import type { LinkProps } from '..';
import isExternalHref from '@/shared/utils/router/isExternalHref';

const usePatchHrefLocale = () => {
  const { language, siteConfig } = useAppContext();
  const router = useRouter();
  const patchHrefLocale = (href: LinkProps['href']) => {
    if (isExternalHref(href.toString())) {
      return href;
    }
    if (href instanceof Object && !href.pathname) {
      // eslint-disable-next-line immutable/no-mutation
      href.pathname = router?.asPath;
    }
    const resolvedHref = resolveHref({} as Router, href);
    const isDefaultLang = language === siteConfig.defaultLang;
    return isDefaultLang ? resolvedHref : `/${language}${resolvedHref}`;
  };
  return { patchHrefLocale };
};

export default usePatchHrefLocale;

import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import GameDetailsRow from './shared/GameDetailsRow';

type Props = Pick<SingleGameFragment, 'payLines'>;

const PaylinesRow: FC<Props> = ({ payLines }) => {
  if (!payLines) {
    return null;
  }
  return (
    <GameDetailsRow translateId="casino.paylines">{payLines}</GameDetailsRow>
  );
};

export default PaylinesRow;

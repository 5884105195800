import { rem } from '@heathmont/moon-utils';
import useNewThumbnailsEnabled from '~/client/hooks/feature/useNewThumbnailsEnabled';

type Props = {
  width: number;
  showGrid?: boolean;
  onClick?: () => void;
};

const GameCardTemplate: FC<Props> = ({
  onClick,
  children,
  width,
  showGrid,
}) => {
  const newThumbsEnabled = useNewThumbnailsEnabled();
  return (
    <div
      className="grid grid-cols-minmax gap-2"
      data-testid="thumb-wrapper"
      onClick={onClick}
      style={{
        maxWidth: showGrid || newThumbsEnabled ? '' : rem(width),
      }}
    >
      {children}
    </div>
  );
};

export default GameCardTemplate;

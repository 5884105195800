import LiveRtp from '~/client/features/LiveRtp';
import useAgentData from '~/client/hooks/useAgentData';
import type GameDetailsTableProps from './GameDetailsTableProps';
import HitRatioRow from './HitRatioRow';
import InLoyaltyRow from './InLoyaltyRow';
import MinMaxBetRow from './MinMaxBetRow';
import PaylinesRow from './PaylinesRow';
import RtpRow from './RtpRow';
import VolatilityRow from './VolatilityRow';
import Translate from '../../i18n/Translate';

const GameDetailsTable: FC<GameDetailsTableProps> = ({
  game,
  hideTitle = false,
}) => {
  const { minBet, maxBet, rtp, payLines, hitRatio, inLoyalty, volatility } =
    game ?? {};
  const { isMobile } = useAgentData();
  const isTitle = !hideTitle && !isMobile;
  if (!game) {
    return null;
  }
  return (
    <div className="grid gap-4">
      {isTitle && (
        <h2 className="text-moon-20 font-medium text-bulma">
          <Translate id="casino.game_stats" />
        </h2>
      )}
      <div className=" grid gap-1 md:w-max">
        <LiveRtp module="details" game={game} />
        <RtpRow rtp={rtp} />
        <MinMaxBetRow minBet={minBet} maxBet={maxBet} />
        <VolatilityRow volatility={volatility} />
        <PaylinesRow payLines={payLines} />
        <HitRatioRow hitRatio={hitRatio} />
        <InLoyaltyRow inLoyalty={inLoyalty} />
      </div>
    </div>
  );
};

export default GameDetailsTable;

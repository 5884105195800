import useAgentData from '~/client/hooks/useAgentData';
import Lazy from './Lazy';

type Props = {
  minHeight?: number | string;
  gridArea?: string;
  placeholder?: JSX.Element;
};

const LazyInView: FC<Props> = ({
  minHeight,
  gridArea,
  placeholder,
  children,
}) => {
  const { isCrawler } = useAgentData();
  if (isCrawler) {
    return <>{children}</>;
  }
  return (
    <Lazy minHeight={minHeight} gridArea={gridArea} placeholder={placeholder}>
      {children}
    </Lazy>
  );
};

export default LazyInView;

import useNewThumbnailsEnabled from '~/client/hooks/feature/useNewThumbnailsEnabled';
import type { LinkProps } from '~/client/shared/NextLink';
import placeholderItems from './helpers/placeholderItems';
import SeeAllLinkPlaceholder from './SeeAllLinkPlaceholder';
import TitleIdPlaceholder from './TitleIdPlaceholder';
import CustomCarousel from '../../Carousel/CustomCarousel';
import Section from '../../PageSection/Section';
import Grid from '../../PageSection/SectionTitle/Grid';

type Props = {
  width?: number;
  height?: number;
  length?: number;
  items?: JSX.Element[];
  showGameInfo?: boolean;
  rtp?: boolean;
  href?: LinkProps['href'];
  gtmLocation?: string;
  id?: string;
  titleId?: string;
  reelClassName?: string;
};

const CarouselPlaceholder: FC<Props> = ({
  length,
  width,
  height,
  href,
  items,
  showGameInfo,
  rtp,
  titleId,
  reelClassName,
}) => {
  const newThumbsEnabled = useNewThumbnailsEnabled();
  const carouselItems =
    items ??
    placeholderItems({
      width: width ?? 164,
      height: height ?? 124,
      length: length ?? 8,
      showGameInfo: !newThumbsEnabled && showGameInfo,
      rtp,
    });

  return (
    <Section>
      <div className="flex flex-col gap-5">
        <Grid>
          <TitleIdPlaceholder titleId={titleId} href={href} />
          <SeeAllLinkPlaceholder href={href} />
        </Grid>
      </div>
      <CustomCarousel items={carouselItems} reelClassName={reelClassName} />
    </Section>
  );
};

export default CarouselPlaceholder;

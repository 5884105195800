/* eslint-disable immutable/no-mutation */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import useDidUpdate from '~/client/hooks/lifecycle/useDidUpdate';

/**
 * @example useInterval(() => console.log('interval'), 1000);
 * @example useInterval(() => console.log('interval'), 1000), [variable1, variable2, variabl3];
 */
const useInterval = (
  callback: () => unknown,
  delay: number,
  inputs: React.DependencyList = [],
  runOnInit = false,
) => {
  const callbackRef = useRef(callback);

  useDidUpdate(() => {
    callbackRef.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (delay) {
      const interval = setInterval(() => callbackRef.current(), delay);
      if (runOnInit) {
        callbackRef.current();
      }
      return () => clearInterval(interval);
    }
  }, [delay, runOnInit, ...inputs]);
};

export default useInterval;

import type { CmsPageFragment } from '~/shared/graphql/content/CmsPageQuery.generated';

type Props = {
  content: string;
  active?: boolean;
  article?: CmsPageFragment;
  replaceLinks?: boolean;
  optinBtnTranslation: string;
};

const getPreparedContent = ({
  content,
  active,
  article,
  optinBtnTranslation,
}: Props) => {
  if (!active || (article && article.participating)) {
    const optInButtonRegex = new RegExp(
      `<button class=["|'](.*?)["|'] data-action=["|']opt-in["|']>(.*?)<\/button>`,
      'g',
    );
    if (!active) {
      if (!content) {
        return '';
      }
      return content.replace(
        optInButtonRegex,
        `<button class="$1" data-testid="optin-disabled" data-action="opt-in" disabled>$2</button>`,
      );
    }
    return content.replace(
      new RegExp(optInButtonRegex, 'g'),
      `<button class="$1" data-action="opt-in" disabled>${optinBtnTranslation}</button>`,
    );
  }
  return content.replace('.mp4', '.mp4#t=0.001');
};

export default getPreparedContent;

import { mergeClassnames } from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import useSite from '~/client/hooks/useSite';
import NextLink from '~/client/shared/NextLink';
import TextTrimmer from '~/client/shared/TextTrimmer';

type Props = { game: GameFragment; href?: string };

const GameName: FC<Props> = ({ game, href }) => {
  const { isBitcasino } = useSite();
  const { isMobile } = useAgentData();
  const fontSize = isMobile ? 'text-moon-14' : 'text-moon-18';
  return (
    <NextLink href={href ?? game.href ?? ''}>
      <TextTrimmer>
        <p
          className={mergeClassnames(
            'text-moon-14 font-semibold text-bulma',
            isBitcasino && `font-headline font-light ${fontSize}`,
          )}
        >
          {game.name}
        </p>
      </TextTrimmer>
    </NextLink>
  );
};

export default GameName;

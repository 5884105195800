import { mergeClassnames } from '@heathmont/moon-core-tw';
import NextLink, { LinkProps } from '~/client/shared/NextLink';

type Props = {
  $controlsOnTopRight?: boolean;
  $isMobile?: boolean;
} & LinkProps;

const SeeAllLink: FC<Props> = ({
  children,
  $controlsOnTopRight,
  $isMobile,
  ...props
}) => (
  <NextLink
    {...props}
    className={mergeClassnames(
      'flex min-h-[1.5rem] items-center text-bulma underline [&_p]:text-moon-14',
      $controlsOnTopRight && 'mr-24',
      $controlsOnTopRight && $isMobile && 'hidden',
    )}
  >
    {children}
  </NextLink>
);

export default SeeAllLink;

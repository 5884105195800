import { useState } from 'react';
import useAgentData from '~/client/hooks/useAgentData';
import CmsPage from '~/client/shared/cms/CmsPage';
import type { CmsPageFragment } from '~/shared/graphql/content/CmsPageQuery.generated';
import CmsView from './CmsView';
import ContainerDesktop from './ContainerDesktop';
import ContainerMobile from './ContainerMobile';
import ShowMoreToggle from './ShowMoreToggle';

type Props = {
  handle?: string;
  section?: string;
  content?: string;
  contentField?: keyof Pick<CmsPageFragment, 'terms' | 'mainBlock'>;
  placeholder?: JSX.Element;
};

const CollapsedCms: FC<Props> = ({
  handle = '',
  section,
  contentField = 'mainBlock',
  placeholder,
  content = '',
}) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const { isMobile } = useAgentData();
  const Container = isMobile ? ContainerMobile : ContainerDesktop;

  const render = (page?: CmsPageFragment) => (
    <Container>
      <CmsView
        article={page}
        content={page?.[contentField] ?? content}
        isCollapsed={isCollapsed}
      />
      <ShowMoreToggle isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
    </Container>
  );

  if (content) {
    return render();
  }

  return (
    <CmsPage
      handle={handle}
      section={section}
      render={render}
      placeholder={placeholder}
    />
  );
};

export default CollapsedCms;

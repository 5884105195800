const getStyleObject = (
  dataAttribs: CmsNodeAttribs,
  style?: string,
  isFixedImgSize?: boolean,
  isMobile?: boolean,
  isTablet?: boolean,
) => {
  if (!style) {
    return { width: undefined, height: undefined };
  }
  const parsedStyle = style
    .split(';')
    .reduce((acc: { [key: string]: number | undefined }, property) => {
      const [key, value] = property.split(':');
      if (!key) {
        return acc;
      }
      const formattedProperty = convertToCamelCase(key?.trim());
      const formattedValue = formatPxValue(
        dataAttribs,
        value?.trim(),
        isFixedImgSize,
        isMobile,
        isTablet,
      );
      return {
        ...acc,
        [formattedProperty]: formattedValue,
      };
    }, {});

  const mobileImgWidth = Number(
    dataAttribs['data-mobile-width']?.replace('px', ''),
  );
  const mobileImgHeight = Number(
    dataAttribs['data-mobile-height']?.replace('px', ''),
  );
  const { width, height } = parsedStyle;
  const desktopImgWidth = Number(
    dataAttribs['data-desktop-width']?.replace('px', ''),
  );
  const desktopImgHeight = Number(
    dataAttribs['data-desktop-height']?.replace('px', ''),
  );

  if (isMobile) {
    return {
      width: mobileImgWidth ?? width,
      height: mobileImgHeight ?? height,
    };
  }
  return {
    width: desktopImgWidth ?? width,
    height: desktopImgHeight ?? height,
  };
};

const formatPxValue = (
  _dataAttribs: CmsNodeAttribs,
  value?: string,
  isFixedImgSize?: boolean,
  isMobile?: boolean,
  isTablet?: boolean,
) => {
  if (!value?.includes('px')) {
    const imgBigSize = isTablet ? 404 : 624;
    const imgSize = isMobile ? 320 : imgBigSize;
    return isFixedImgSize ? imgSize : undefined;
  }
  const removedPx = value.replace('px', '');
  return Number(removedPx);
};

const convertToCamelCase = (key: string) => {
  const splitted = key.split('-');
  if (splitted.length === 1) {
    return splitted[0];
  }
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0]?.toUpperCase() + word.slice(1))
      .join('')
  );
};

export default getStyleObject;

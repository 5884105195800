import { createContext } from 'react';
// eslint-disable-next-line no-restricted-imports
import bitcasino from '~/server/shared/getSiteInfo/getSiteConfig/bitcasino';
import DeviceType from '~/shared/DeviceType';

const AppContext = createContext<AppContextProps>({
  site: 'bitcasino',
  brand: 'bitcasino',
  pathPrefix: '',
  translations: {},
  params: {},
  language: 'en',
  languages: ['en'],
  countryCode: 'EE',
  domain: '',
  sidebarItems: { common: [] },
  sidebarProviders: [],
  sidebarSections: [],
  captchaConfig: {
    isLoginCaptchaRequired: true,
    isRegistrationCaptchaRequired: true,
  },
  userAgentData: {
    userAgent: '',
    isCrawler: false,
    isDesktop: false,
    isTablet: false,
    isMobile: true,
    deviceType: DeviceType.ANDROID_PHONE,
  },
  siteConfig: bitcasino,
  featureFlags: {},
  telegramAPI: {},
});

export default AppContext;

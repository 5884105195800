import type { CmsPageQueryVariables } from '../CmsPageQuery.generated';

type Args = {
  handle?: string;
  section?: string;
  optedIn?: boolean;
  showPublished?: boolean;
  language: string;
  site: Site;
};

const getCmsPageVariables = ({
  handle,
  optedIn,
  showPublished,
  language,
  site,
  section = 'site-blocks',
}: Args): CmsPageQueryVariables => ({
  filter: {
    handle,
    language,
    section,
    site,
    showPublished,
    optedIn,
  },
});

export default getCmsPageVariables;

import { mergeClassnames } from '@heathmont/moon-core-tw';
import Img from '~/client/shared/images/Img';
import NextLink from '~/client/shared/NextLink';
import TextTrimmer from '~/client/shared/TextTrimmer';
import getProviderIcon from './getProviderIcon';

type Props = { game?: GameFragment; isBetRangeVisible: boolean };

const GameProvider: FC<Props> = ({ game, isBetRangeVisible = false }) => {
  const provider = game?.menuItem?.[0];
  return (
    <NextLink
      href={`/providers/${provider?.handle}`}
      prefetch={false}
      className={mergeClassnames(
        'z-1 absolute bottom-2 rtl:bottom-1 rtl:sm:bottom-2 left-2 sm:left-4 sm:max-md:bottom-4 lg:max-2xl:bottom-4 3xl:bottom-4 rtl:right-2 rtl:sm:right-4',
        isBetRangeVisible && 'bottom-5 sm:bottom-5',
      )}
    >
      <div className="flex items-center gap-1 sm:gap-2">
        {provider?.handle && (
          <Img
            width={20}
            height={20}
            alt=""
            src={getProviderIcon(provider.handle)}
          />
        )}
        <TextTrimmer>
          <p className="text-moon-12 text-goten/70">
            {provider?.name ?? '\u00A0'}
          </p>
        </TextTrimmer>
      </div>
    </NextLink>
  );
};

export default GameProvider;

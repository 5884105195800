import { useCallback } from 'react';
import useUserPreferenceId from '~/client/hooks/cookies/useUserPreferenceId';
import useThemeContext from '~/client/hooks/theme/useThemeContext';
import useSite from '~/client/hooks/useSite';
import useCookie from '~/shared/hooks/useCookie';
import getCurrentTheme from '~/shared/utils/theme/getCurrentTheme';
import { useUpdateSportsbetColorSchemeMutation } from './graphql/UpdateSportsbetColorScheme.generated';

const useThemeSwitcher = () => {
  const { site, isBridgeSite } = useSite();
  const { setTheme, colorScheme, setColorScheme } = useThemeContext();
  const [, setCookie] = useCookie('theme');
  const [id] = useUserPreferenceId();
  const [sportsbetUpdateColorScheme] = useUpdateSportsbetColorSchemeMutation();

  const handleClick = useCallback(async () => {
    const newColorScheme: ColorScheme =
      colorScheme === 'dark' ? 'light' : 'dark';

    setCookie(newColorScheme);

    setColorScheme(newColorScheme);
    setTheme(getCurrentTheme(site, newColorScheme));

    if (isBridgeSite) {
      sportsbetUpdateColorScheme({
        variables: {
          input: {
            site,
            colorScheme: newColorScheme.toUpperCase() as Uppercase<ColorScheme>,
            id,
          },
        },
      });
    }
  }, [
    colorScheme,
    id,
    isBridgeSite,
    setColorScheme,
    setCookie,
    setTheme,
    site,
    sportsbetUpdateColorScheme,
  ]);

  return { colorScheme, handleClick };
};

export default useThemeSwitcher;

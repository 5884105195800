import { useEffect } from 'react';
import useTranslation from '~/client/hooks/i18n/useTranslation';
import useLanguage from '~/client/hooks/useLanguage';
import useSite from '~/client/hooks/useSite';
import type CmsContainerProps from '~/client/shared/cms/CmsContainer/CmsContainerProps';
import applyImgix from '~/client/shared/images/imgix/applyImgix';
import getPreparedContent from './getPreparedContent';
import { useOptInMutation } from './OptInMutation.generated';

const useCmsContainer = ({
  onLoad,
  active,
  article,
  content,
  useImgx,
  imgixParams,
}: CmsContainerProps) => {
  const { site } = useSite();
  const { code: language } = useLanguage();
  const optinBtnTranslation = useTranslation('general.button.participating');
  useEffect(() => onLoad?.(), [onLoad]);
  const preparedContent = getPreparedContent({
    active,
    article,
    content,
    optinBtnTranslation,
  });
  const finalContent = useImgx
    ? applyImgix({ src: preparedContent, ...imgixParams })
    : preparedContent;

  const { handle = '', sectionHandle = '' } = article ?? {};
  const [contentOptIn] = useOptInMutation({
    variables: {
      site,
      language,
      page: handle,
      section: sectionHandle,
    },
  });
  return { finalContent, contentOptIn };
};

export default useCmsContainer;

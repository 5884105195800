import { mergeClassnames } from '@heathmont/moon-core-tw';

const ContainerMobile: FC = ({ children }) => (
  <div
    className={mergeClassnames(
      'rounded-moon-s-xs bg-gohan px-4 py-2',
      '[&_a]:text-bulma [&_a]:active:text-hit',
      '[&_div_h3]:mb-4 [&_div_h3]:mt-[10px] [&_div_h3]:text-moon-20 [&_div_h3]:font-medium [&_div_h3]:leading-[1.375]',
      '[&_.two-columns]:rounded-moon-s-xs [&_.two-columns]:border [&_.two-columns]:border-solid [&_.two-columns]:border-beerus [&_.two-columns]:pb-8',
      '[&_.two-columns_.group-inner]:overflow-hidden [&_.two-columns_.group-inner]:px-4 [&_.two-columns_.group-inner]:text-trunks',
      '[&_.two-columns_.narrow_a]:opacity-100 [&_.two-columns_.narrow_p]:opacity-60',
    )}
  >
    {children}
  </div>
);

export default ContainerMobile;

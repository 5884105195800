import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import GameDetailsRow from './shared/GameDetailsRow';

type Props = Pick<SingleGameFragment, 'hitRatio'>;

const HitRatioRow: FC<Props> = ({ hitRatio }) => {
  if (!hitRatio) {
    return null;
  }
  return (
    <GameDetailsRow translateId="casino.hit_ratio">{hitRatio}%</GameDetailsRow>
  );
};

export default HitRatioRow;

import { mergeClassnames } from '@heathmont/moon-core-tw';
import Image, { ImageLoaderProps } from 'next/image';

type Props = ImgProps & { width: number; height: number };

const EagerImg: FC<Props> = ({
  id,
  src,
  className,
  width,
  height,
  onLoad,
  onClick,
  dataAction,
  style,
  alt = '',
}) => {
  if (!src) {
    // eslint-disable-next-line @next/next/no-img-element
    return <img src={src} alt={alt} className={className} />;
  }
  return (
    <Image
      priority
      id={id}
      alt={alt}
      src={src}
      width={width}
      height={height}
      onClick={onClick}
      onLoad={onLoad}
      className={mergeClassnames(className)}
      data-action={dataAction}
      loader={loader}
      unoptimized
      style={style}
    />
  );
};

const loader = ({ src }: ImageLoaderProps) => {
  if (src.startsWith('http')) {
    return src;
  }
  return `https:${src}`;
};

export default EagerImg;

import { mergeClassnames } from '@heathmont/moon-core-tw';
import useAgentData from '~/client/hooks/useAgentData';
import Carousel from '~/client/shared/Carousel';
import CarouselButton from '../CarouselButton';

type Props = {
  items: (JSX.Element | null)[];
  step?: number;
  scrollTo?: number;
  big?: boolean;
  type?: 'game-carousel' | 'regular';
  controlsOnTopRight?: boolean;
  reelClassName?: string;
};

const CustomCarousel: FC<Props> = ({
  items,
  scrollTo,
  reelClassName,
  step,
  controlsOnTopRight = false,
}) => {
  const { isDesktop } = useAgentData();

  const getScrollToButton = (direction: 'left' | 'right') => {
    if (!isDesktop) {
      return null;
    }
    return (
      <CarouselButton
        className={mergeClassnames(
          !controlsOnTopRight && 'disabled:hidden',
          controlsOnTopRight && '-top-12 ltr:left-auto rtl:right-auto',
          controlsOnTopRight &&
            direction === 'right' &&
            'ltr:right-0 rtl:left-[9.5rem]',
          controlsOnTopRight &&
            direction === 'left' &&
            'ltr:right-10 rtl:left-[7rem]',
          'h-auto rounded-full p-2 z-2',
        )}
        iconClassName="rem:stroke-[4px] text-trunks rem:text-[10px]  "
        direction={direction}
      />
    );
  };

  return (
    <Carousel
      reelClassName={reelClassName}
      scrollTo={scrollTo}
      step={step}
      items={items}
      scrollToLeftButton={getScrollToButton('left')}
      scrollToRightButton={getScrollToButton('right')}
    />
  );
};

export default CustomCarousel;

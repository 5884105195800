import { MediaTuner } from '@heathmont/moon-icons';
import { rem } from '@heathmont/moon-utils';
import Translate from '~/client/shared/i18n/Translate';
import ListBox from '../shared/ListBox';
import useListBoxState from '../shared/useListBoxState';
import type SortButtonProps from '../SortButtonProps';

const DesktopSortButton: FC<SortButtonProps> = ({
  options,
  selectedValue,
  onChange,
}) => {
  const { isOpen, handleClick, handleChange } = useListBoxState({ onChange });
  return (
    <div className="relative w-fit">
      <div
        className="flex cursor-pointer items-center gap-2 border-0 bg-transparent py-2 pl-1 pr-3 text-base text-trunks hover:text-bulma"
        onClick={handleClick}
        data-testid="desktop-sort-button"
      >
        <MediaTuner fontSize={rem(24)} />
        <Translate id="game-carousel-sorter.title" />
      </div>

      {isOpen && (
        <ListBox
          options={options}
          selectedValue={selectedValue}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default DesktopSortButton;

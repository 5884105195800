/* eslint-disable no-restricted-imports */
import Cookies from 'js-cookie';
import CookieContext from '~/shared/context/Cookie/CookieContext';

const CookieContextProvider: FC = ({ children }) => (
  <CookieContext.Provider
    value={{
      cookies: Cookies.get(),
      setCookie: Cookies.set,
      removeCookie: Cookies.remove,
      getCookie: Cookies.get,
    }}
  >
    {children}
  </CookieContext.Provider>
);

export default CookieContextProvider;

import applyImgixToUrl from './applyImgixToUrl';

const getImgixSrc = ({
  src,
  width,
  height,
  aspectRatio,
  mono,
  blur,
  mask,
  fit,
  useDpr2x,
  useDpr3x,
  crop,
  mark,
}: ImgProps) =>
  applyImgixToUrl({
    src,
    w: width,
    h: height,
    ar: aspectRatio,
    mono,
    blur,
    mask,
    fit,
    cdnDomain: '',
    useDpr2x,
    useDpr3x,
    crop,
    mark,
  });

export default getImgixSrc;

import useCurrencyDisplay from '~/client/hooks/currency/useCurrencyDisplay';
import type Props from './Props';

const CurrencyDisplay: FC<Props> = ({
  amount,
  currency,
  coinInSatoshi,
  minimumFractionDigits,
  maximumFractionDigits,
  moneyOnly,
  currencyOnly,
  amountOnly,
  showSign,
  isFormatted,
}) => {
  const { icon, money, moneyIcon, moneyIconSign } = useCurrencyDisplay(amount, {
    currency,
    coinInSatoshi,
    minimumFractionDigits,
    maximumFractionDigits,
  });
  if (isFormatted) {
    return <span>{`${amount} ${icon}`}</span>;
  }
  if (moneyOnly) {
    return <span>{money || ''}</span>;
  }
  if (currencyOnly) {
    return <span>{icon || ''}</span>;
  }
  if (amountOnly) {
    return <span>{amount || ''}</span>;
  }
  if (showSign) {
    return <span>{moneyIconSign || ''}</span>;
  }
  return <span>{moneyIcon || ''}</span>;
};

export default CurrencyDisplay;

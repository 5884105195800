import useTranslation from '~/client/hooks/i18n/useTranslation';

type Props = {
  id: string;
  values?: { [key: string]: string | number };
  className?: string;
};

const Translate: FC<Props> = ({ id, values, className }) => {
  const translation = useTranslation(id, { values });
  return (
    <span className={className} data-translation={id}>
      {translation}
    </span>
  );
};

export default Translate;

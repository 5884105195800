import useAppContext from '../../../shared/context/AppContext/useAppContext';

const languageCodeToLocaleMapper: Record<LanguageCode, LanguageLocale> = {
  ar: 'ar',
  de: 'de-DE',
  en: 'en-GB',
  es: 'es-ES',
  fi: 'fi',
  fr: 'fr-FR',
  ja: 'ja-JP',
  ko: 'ko-KR',
  no: 'no-NO',
  pt: 'pt-BR',
  th: 'th-TH',
  tr: 'tr-TR',
  vi: 'vi-VN',
  zh: 'zh-Hans',
};

const useLanguage = () => {
  const { language: code } = useAppContext();
  return { code, locale: languageCodeToLocaleMapper[code] ?? 'en-GB' };
};

export default useLanguage;

import DeviceType from '~/shared/DeviceType';

const parse = (ua?: string | null) => {
  if (!ua) {
    return { isCrawler: false, deviceType: DeviceType.ANDROID_PHONE };
  }
  const isCrawler = isSpider(ua);
  const deviceType = getDeviceType(ua);
  return { isCrawler, deviceType };
};

const isSpider = (ua = '') =>
  /bot|google-inspectiontool|googlebot|crawler|baiduspider|yandexbot|spider|facebookexternalhit|linkedinbot|bingbot|twitterbot|slackbot|telegrambot|applebot|robot|crawling/i.test(
    ua,
  );

const getDeviceType = (ua = ''): DeviceType => {
  if (ua.includes('Tablet')) {
    if (ua.includes('Windows')) {
      return DeviceType.WINDOWS_PHONE;
    }
    return DeviceType.ANDROID_TABLET;
  }
  if (ua.includes('iPad')) {
    return DeviceType.IOS_TABLET;
  }
  if (ua.includes('Android')) {
    return DeviceType.ANDROID_PHONE;
  }
  if (/iPhone|iPod/.test(ua)) {
    return DeviceType.IOS_PHONE;
  }
  if (/IEMobile|Windows Phone|Lumia/i.test(ua)) {
    return DeviceType.WINDOWS_PHONE;
  }
  return DeviceType.DESKTOP;
};

export default parse;

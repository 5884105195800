import useRouter from '~/client/hooks/router/useRouter';
import Translate from '~/client/shared/i18n/Translate';
import type { LinkProps } from '~/client/shared/NextLink';
import sendInteractionToGtm from '~/shared/gtm/sendInteractionToGtm';
import SeeAllLink from '../../../PageSection/SectionTitle/SeeAllLink';

type Props = {
  href?: LinkProps['href'];
  gtmLocation?: string;
};

const SeeAllLinkPlaceholder: FC<Props> = ({ href, gtmLocation }) => {
  const router = useRouter();
  const hrefToString = href?.toString();
  const showSeeAll = router.pathname.includes(hrefToString || '');

  const sendToGtm = () =>
    sendInteractionToGtm({
      eventCategory: 'text_link',
      eventAction: gtmLocation ?? 'games_slider',
    });

  if (href && !showSeeAll) {
    return (
      <SeeAllLink href={href} onClick={sendToGtm}>
        <p className="text-moon-14 font-semibold">
          <Translate id="casino.see_all" />
        </p>
      </SeeAllLink>
    );
  }
  return null;
};

export default SeeAllLinkPlaceholder;

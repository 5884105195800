import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import useAgentData from '~/client/hooks/useAgentData';
import useSite from '~/client/hooks/useSite';
import DesktopInfoTooltip from './DesktopInfoTooltip';
import GameDetailsTooltip from '../../../GameDetailsTooltip';
import InfoButton from '../InfoButton';

type Props = {
  game: SingleGameFragment;
};

const InfoTooltip: FC<Props> = ({ game }) => {
  const { isEmpire } = useSite();
  const { isDesktop } = useAgentData();

  if (isEmpire || !game.rtp || !isDesktop) {
    return null;
  }

  return (
    <DesktopInfoTooltip
      trigger={<InfoButton />}
      content={<GameDetailsTooltip game={game} />}
    />
  );
};

export default InfoTooltip;

import { createContext } from 'react';

type Props = {
  cookies: { [key: string]: unknown };
  getCookie: (name: string) => unknown;
  setCookie: (
    name: string,
    cookieValue: string,
    options?: CookieOptions,
  ) => void;
  removeCookie: (name: string, options?: CookieOptions) => void;
};

const CookieContext = createContext<Props>({
  cookies: {},
  getCookie: (_) => undefined,
  setCookie: (_) => undefined,
  removeCookie: (_) => undefined,
});

export default CookieContext;

import { useCallback } from 'react';
import { resolveHref } from 'next/dist/client/resolve-href';
import useRouter from '~/client/hooks/router/useRouter';
import useSite from '~/client/hooks/useSite';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import replaceMappedPath from './helpers/replaceMappedPath';
import usePatchHrefLocale from './usePatchHrefLocale';
import type { LinkProps } from '..';

const BRIDGE_SITE_PATH_LIST = [
  '/about',
  '/promotions',
  '/news',
  '/vip',
  '/support',
  '/sports',
  '/id',
  '/user',
  '/clubhouse',
  '/help-centre',
];

const useParseExternalHref = () => {
  const { isBridgeSite } = useSite();
  const router = useRouter();
  const { domain } = useAppContext();
  const { patchHrefLocale } = usePatchHrefLocale();

  const parseExternalHref = useCallback(
    (href: LinkProps['href']) => {
      const parsedHref = resolveHref(router, href);
      if (!isBridgeSite) {
        return parsedHref;
      }
      const mappedHref = replaceMappedPath(parsedHref);
      for (const path of BRIDGE_SITE_PATH_LIST) {
        if (mappedHref.startsWith(path)) {
          return `https://${domain}${patchHrefLocale(mappedHref)}`;
        }
      }
      return mappedHref;
    },
    [domain, isBridgeSite, patchHrefLocale, router],
  );
  return { parseExternalHref };
};

export default useParseExternalHref;

import { Fragment } from 'react';
import Img from '~/client/shared/images/Img';
import getStyleObject from './getStyleObject';

const replaceImage = (
  attribs: CmsNodeAttribs,
  isMobile?: boolean,
  isFixedImgSize?: boolean,
  isTablet?: boolean,
) => {
  const { width, height } = getStyleObject(
    attribs,
    attribs?.style,
    isFixedImgSize,
    isMobile,
    isTablet,
  );
  if (!isMobile && attribs?.class?.includes('mobile')) {
    return <Fragment />;
  }
  if (isMobile && attribs?.class?.includes('desktop')) {
    return <Fragment />;
  }

  return (
    <Img
      width={width}
      height={height}
      className={attribs?.class}
      src={attribs?.src}
      alt={attribs?.alt}
      dataAction={attribs['data-action']}
    />
  );
};

export default replaceImage;

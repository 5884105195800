import useAgentData from '~/client/hooks/useAgentData';
import useLanguage from '~/client/hooks/useLanguage';
import useCurrentUser from '~/client/hooks/user/useCurrentUser';
import useSite from '~/client/hooks/useSite';
import type { CasinoGameConnectionSorter } from '~/schema/public';
import { useGameCarouselQuery } from '~/shared/graphql/home/GameCarouselQuery.generated';
import getGameCarouselVariables from '~/shared/graphql/home/helpers/getGameCarouselVariables';

type Props = {
  menuItem?: string;
  theme?: string;
  first?: number;
  order?: -1 | 1;
  sortBy?: keyof CasinoGameConnectionSorter;
  currentPlayingId?: string;
};

const useGameCarousel = ({
  menuItem,
  theme,
  order,
  sortBy,
  currentPlayingId,
}: Props) => {
  const { site } = useSite();
  const { code: language } = useLanguage();
  const { deviceType } = useAgentData();
  const { loading: loadingUser } = useCurrentUser();
  const variables = getGameCarouselVariables({
    menuItem,
    theme,
    order,
    sortBy,
    site,
    language,
    deviceType,
  });
  const { data, loading } = useGameCarouselQuery({
    fetchPolicy: 'network-only',
    variables,
    skip: loadingUser,
  });
  const edges = data?.casino?.games?.edges;
  const games = !currentPlayingId
    ? edges
    : edges?.filter((game) => game?.node?.id !== currentPlayingId);

  return { games, loading };
};

export default useGameCarousel;

const weirdChars = /[!@#$%^&*()+=\[\]{};':"\\|<>\/\s]+/;

const isWeirdKey = (key: string) => {
  if (typeof key !== 'string') {
    return false;
  }

  return !key.includes('.') || key.startsWith('site.') || weirdChars.test(key);
};

export default isWeirdKey;

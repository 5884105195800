import type { CasinoGameConnectionSorter } from '~/schema/public';
import mapSortByToRtpField from './mapSortByToRtpField';

const getRtpFilters = (sortBy?: keyof CasinoGameConnectionSorter) => {
  const rtpField = mapSortByToRtpField(sortBy);
  if (rtpField) {
    return { [rtpField]: { gt: 0 } };
  }

  return {};
};

export default getRtpFilters;

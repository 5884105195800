const stripLocaleFromPath = (path: string) => {
  const pathLocaleRegex = /\/([\w]{2})\//;
  const endLocaleRegex = /\/([\w]{2})$/;
  const locale = path.match(pathLocaleRegex) || path.match(endLocaleRegex);

  const localeToRemove = locale === null ? '/' : locale[0];
  const finalPath = path.replace(localeToRemove, '/');

  return finalPath;
};

export default stripLocaleFromPath;

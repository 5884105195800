import dynamic from 'next/dynamic';
import useFeature from '~/client/hooks/feature/useFeature';

type Props = {
  module: 'details' | 'home' | 'lists' | 'gameBanner' | 'tournament';
};

const TournamentLists = dynamic(() => import('./modules/TournamentLists'));
const HomeTournaments = dynamic(() => import('./modules/HomeTournaments'), {
  ssr: false,
});
const TournamentDetails = dynamic(() => import('./modules/TournamentDetails'));
const GameBanner = dynamic(() => import('./modules/GameBanner'), {
  ssr: false,
});
const CurrentTournaments = dynamic(() => import('./modules/Tournament'), {
  ssr: false,
});

const Tournaments: FC<Props> = ({ module }) => {
  const isTournamentsEnabled = useFeature('isGameLeaderboardsEnabled');
  if (!isTournamentsEnabled) {
    return null;
  }

  switch (module) {
    case 'lists': {
      return <TournamentLists />;
    }
    case 'home': {
      return <HomeTournaments />;
    }
    case 'gameBanner': {
      return <GameBanner lazyMinHeight={0} />;
    }
    case 'tournament': {
      return <CurrentTournaments />;
    }
    default: {
      return <TournamentDetails />;
    }
  }
};

export default Tournaments;

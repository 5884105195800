import type {
  CasinoGameConnectionSorter,
  currencyConversion,
} from '~/schema/public';
import getRtpFilters from '~/shared/utils/carousel/getRtpFilters';
import type { GameCarouselQueryVariables } from '../GameCarouselQuery.generated';

type Props = {
  menuItem?: string;
  theme?: string;
  first?: number;
  order?: -1 | 1;
  sortBy?: keyof CasinoGameConnectionSorter;
  site: Site;
  language: string;
  deviceType: UserAgentData['deviceType'];
  currency?: currencyConversion;
};

const getGameCarouselVariables = ({
  menuItem,
  theme,
  order,
  sortBy,
  site,
  language,
  deviceType,
  currency = 'USDT',
  first = 12,
}: Props): GameCarouselQueryVariables => {
  const rtpFilters = getRtpFilters(sortBy);
  return {
    first,
    language,
    currency,
    filter: {
      menuItem,
      theme,
      site,
      type: deviceType,
      currency,
      ...rtpFilters,
    },
    sort: { ...getSort(order, sortBy), language },
  };
};

const getSort = (
  order: -1 | 1 = -1,
  sortBy: keyof CasinoGameConnectionSorter = 'popular',
): CasinoGameConnectionSorter => ({ [sortBy]: order });

export default getGameCarouselVariables;

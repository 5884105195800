import { mergeClassnames } from '@heathmont/moon-core-tw';

const LazyImg: FC<ImgProps> = ({
  id,
  className,
  width,
  height,
  src,
  onLoad,
  onClick,
  alt = '',
  dataAction,
  lazy = true,
  style,
}) => (
  // eslint-disable-next-line @next/next/no-img-element
  <img
    id={id}
    alt={alt}
    className={mergeClassnames(className)}
    src={src}
    width={width}
    height={height}
    onClick={onClick}
    onLoad={onLoad}
    data-action={dataAction}
    loading={lazy ? 'lazy' : 'eager'}
    style={style}
  />
);

export default LazyImg;

import type PageSectionProps from '~/client/shared/PageSection/PageSectionProps';
import Section from './Section';
import SectionTitle from './SectionTitle';

const PageSection: FC<PageSectionProps> = ({
  href,
  titleId,
  title,
  subtitleId,
  children,
  controlsOnTopRight,
  gameCount,
  onChange,
  gameCarouselSorterIsActive,
  selectedSortValue,
  id,
}) => (
  <Section id={id}>
    <SectionTitle
      titleId={titleId}
      href={href}
      title={title}
      subtitleId={subtitleId}
      controlsOnTopRight={controlsOnTopRight}
      gameCount={gameCount}
      gameCarouselSorterIsActive={gameCarouselSorterIsActive}
      onChange={onChange}
      selectedSortValue={selectedSortValue}
    />
    {children}
  </Section>
);

export default PageSection;

import { rem } from '@heathmont/moon-utils';

type Props = {
  width?: number | string;
  height?: number | string;
};

const Placeholder: FC<Props> = ({ width, height, children }) => (
  <div
    style={{
      width: getSizeValue(width),
      height: getSizeValue(height),
    }}
    className="rounded-moon-s-xs bg-gradient-to-r from-goku to-gohan"
  >
    {children}
  </div>
);

const getSizeValue = (size?: number | string) => {
  if (!size) {
    return undefined;
  }
  if (typeof size === 'number') {
    return rem(size);
  }
  return size;
};

export default Placeholder;

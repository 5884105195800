/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable immutable/no-mutation */
import { useEffect, useRef } from 'react';

const useDidUpdate = (
  effects: React.EffectCallback,
  inputs: React.DependencyList,
) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      effects();
      return;
    }
    didMountRef.current = true;
  }, inputs);
};

export default useDidUpdate;

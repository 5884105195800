import GameCardTemplate from '~/client/shared/game/GameCard/template/GameCardTemplate';
import Placeholder from '~/client/shared/LazyLoad/Placeholder';
import rangeArray from '~/shared/utils/rangeArray';

type Props = {
  width: number;
  height: number;
  length?: number;
  showGameInfo?: boolean;
  rtp?: boolean;
};

const placeholderItems = ({
  length,
  width,
  height,
  rtp,
  showGameInfo = true,
}: Props) => {
  const items = rangeArray(length ?? 4);
  const gameInfoHeight = rtp ? 68 : 40;
  return items.map((key) => (
    <GameCardTemplate key={key} width={width}>
      <Placeholder width={width} height={height} />
      {showGameInfo && <Placeholder width={width} height={gameInfoHeight} />}
    </GameCardTemplate>
  ));
};

export default placeholderItems;

import { mergeClassnames } from '@heathmont/moon-core-tw';
import Translate from '~/client/shared/i18n/Translate';
import type { CasinoGameConnectionSorter } from '~/schema/public';

type Props = {
  onChange: (value: keyof CasinoGameConnectionSorter) => void;
  isSelected: boolean;
  value: keyof CasinoGameConnectionSorter;
  label: string;
};

const ListBoxItem = ({ onChange, isSelected, value, label }: Props) => {
  const selectedStyle = isSelected
    ? 'bg-heles font-semibold text-bulma'
    : 'bg-transparent font-normal text-trunks';
  return (
    <button
      className={mergeClassnames(
        'z-10000 cursor-pointer rounded-lg border-0 p-4 text-left hover:text-bulma',
        selectedStyle,
      )}
      onClick={() => onChange(value)}
      data-testid={`sort-button-listbox-options-${value}`}
    >
      <Translate id={label} />
    </button>
  );
};

export default ListBoxItem;

import CmsContainer from '~/client/shared/cms/CmsContainer';
import Translate from '~/client/shared/i18n/Translate';
import type { CmsPageFragment } from '~/shared/graphql/content/CmsPageQuery.generated';
import useCmsPage from './useCmsPage';

type Props = {
  handle: string;
  section?: string;
  showPublished?: boolean;
  notFoundTextKey?: string;
  className?: string;
  useImgx?: boolean;
  contentKey?: 'mainBlock' | 'terms';
  placeholder?: JSX.Element;
  render?: (page: CmsPageFragment) => JSX.Element;
  renderEmpty?: () => JSX.Element;
};

const CmsPage: FC<Props> = ({
  handle,
  section,
  showPublished,
  className,
  useImgx,
  notFoundTextKey,
  contentKey = 'mainBlock',
  placeholder,
  render,
  renderEmpty,
}) => {
  const { loading, error, data } = useCmsPage({
    handle,
    section,
    showPublished,
  });

  if (loading || error) {
    return placeholder ? placeholder : null;
  }
  if (!data?.content?.pages?.edges?.length && renderEmpty) {
    return renderEmpty();
  }
  const page = data?.content?.pages?.edges?.[0]?.node;
  if (page) {
    return (
      render?.(page) ?? (
        <CmsContainer
          useImgx={useImgx}
          article={page}
          content={page[contentKey] ?? ''}
          className={className}
        />
      )
    );
  }
  if (notFoundTextKey) {
    return <Translate id={notFoundTextKey} />;
  }
  return null;
};

export default CmsPage;

import { mergeClassnames } from '@heathmont/moon-core-tw';
import useRouter from '~/client/hooks/router/useRouter';
import useAgentData from '~/client/hooks/useAgentData';
import useSite from '~/client/hooks/useSite';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';
import type PageSectionProps from '~/client/shared/PageSection/PageSectionProps';
import type { CasinoGameConnectionSorter } from '~/schema/public';
import sendInteractionToGtm from '~/shared/gtm/sendInteractionToGtm';
import Grid from './Grid';
import SeeAllLink from './SeeAllLink';
import GameCarouselSorter from '../../game/GameCarousel/GameCarouselSorter';

const maxBetTitleId = 'game-carousel-sorter.max_bet';
const minBetTitleId = 'game-carousel-sorter.min_bet';

const SectionTitle: FC<PageSectionProps> = ({
  title,
  href,
  subtitleId,
  gtmLocation,
  titleId = '',
  controlsOnTopRight,
  gameCount,
  onChange,
  gameCarouselSorterIsActive,
  selectedSortValue,
}) => {
  const { isMobile } = useAgentData();
  const { siteConfig, isBitcasino } = useSite();
  const router = useRouter();
  const hrefToString = href?.toString();
  const showSeeAll = router.pathname.includes(hrefToString || '');

  const sendToGtm = () =>
    sendInteractionToGtm({
      eventCategory: 'text_link',
      eventAction: gtmLocation ?? 'games_slider',
    });

  const sorterOnChange = (value: keyof CasinoGameConnectionSorter) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Grid>
      <div>
        {href && (
          <NextLink href={href} onClick={sendToGtm}>
            <h4
              className={mergeClassnames(
                'text-moon-16 font-medium leading-5 text-bulma sm:text-moon-20',
                controlsOnTopRight && 'font-normal sm:text-moon-24',
                isBitcasino &&
                  'font-headline text-moon-20 font-light sm:text-moon-24',
              )}
            >
              {title ?? (
                <Translate
                  id={titleId}
                  values={{ siteName: siteConfig.name }}
                />
              )}
            </h4>
          </NextLink>
        )}
        {!href && (
          <h4
            className={mergeClassnames(
              'whitespace-nowrap text-moon-16 font-medium text-bulma sm:text-moon-20',
              controlsOnTopRight && 'font-normal sm:text-moon-24',
              isBitcasino &&
                'font-headline text-moon-20 font-light sm:text-moon-24',
            )}
          >
            {title ?? (
              <Translate id={titleId} values={{ siteName: siteConfig.name }} />
            )}
          </h4>
        )}
        {subtitleId && (
          <p className="text-moon-14 text-trunks sm:text-moon-16">
            <Translate id={subtitleId} />
          </p>
        )}
      </div>
      {gameCarouselSorterIsActive && (
        <GameCarouselSorter
          onChange={(e) => sorterOnChange(e)}
          options={[
            { label: maxBetTitleId, value: 'maxBet' },
            { label: minBetTitleId, value: 'minBet' },
          ]}
          selectedValue={selectedSortValue}
        />
      )}
      {!showSeeAll && href && (
        <SeeAllLink
          href={href}
          onClick={sendToGtm}
          $controlsOnTopRight={controlsOnTopRight}
          $isMobile={isMobile}
        >
          <p
            className={mergeClassnames(
              'text-moon-20 font-semibold xl:text-moon-14',
              controlsOnTopRight && 'text-moon-14',
            )}
          >
            <Translate id="casino.see_all" />
            {controlsOnTopRight && ` (${gameCount})`}
          </p>
        </SeeAllLink>
      )}
    </Grid>
  );
};

export default SectionTitle;

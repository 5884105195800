import getTheme from './getTheme';

const THEME_RECORD: Record<Site, Record<ColorScheme, Theme>> = {
  bitcasino: {
    dark: getTheme('bitcasinoDark'),
    light: getTheme('bitcasinoLight'),
  },
  livecasino: {
    dark: getTheme('livecasinoDark'),
    light: getTheme('livecasinoLight'),
  },
  sportsbet: {
    dark: getTheme('sportsbetDark'),
    light: getTheme('sportsbetLight'),
  },
  sportsbetmx: {
    dark: getTheme('sportsbetDark'),
    light: getTheme('sportsbetLight'),
  },
  empire: {
    light: getTheme('empireLight'),
    dark: getTheme('empireDark'),
  },
};

const getCurrentTheme = (site: Site, colorScheme: ColorScheme) =>
  THEME_RECORD[site][colorScheme];

export default getCurrentTheme;

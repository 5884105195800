import { Button } from '@heathmont/moon-core-tw';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';

type Props = {
  handle: string;
};

const ThemeLink: FC<Props> = ({ handle }) => {
  if (!handle) {
    return null;
  }
  return (
    <div className="mb-2 mr-2 inline-block">
      <NextLink href={`/themes/${handle}`}>
        <Button size="xs" variant="outline" className="bg-transparent">
          <span className="text-moon-10-caption font-medium uppercase text-bulma">
            #<Translate id={`casino.theme.${handle}`} />
          </span>
        </Button>
      </NextLink>
    </div>
  );
};

export default ThemeLink;

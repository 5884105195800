'use client';

import { useInView } from 'react-hook-inview';
import parseUserAgent from '~/shared/getUserAgent/parseUserAgent';

type Props = {
  minHeight?: number | string;
  gridArea?: string;
  placeholder?: JSX.Element;
};

const Lazy: FC<Props> = ({ minHeight, gridArea, placeholder, children }) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true });

  const { isCrawler } = parseUserAgent(global?.navigator?.userAgent);

  if (isCrawler) {
    return children;
  }

  return (
    <div
      ref={ref}
      style={{ minHeight, gridArea }}
      className={isVisible ? 'lazyContainer' : ''}
    >
      {isVisible ? children : placeholder}
    </div>
  );
};

export default Lazy;

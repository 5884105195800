import useNewThumbnailsEnabled from '~/client/hooks/feature/useNewThumbnailsEnabled';
import useAgentData from '~/client/hooks/useAgentData';

const useGameCardSize = (isBig = false, tournaments = false) => {
  const newThumbsEnabled = useNewThumbnailsEnabled();
  const { isDesktop, isTablet } = useAgentData();
  const size = isBig ? 'big' : 'small';
  const variant = newThumbsEnabled ? 'new' : size;
  if (!newThumbsEnabled && tournaments) {
    return sizes.desktop.tournaments;
  }
  if (isDesktop) {
    return sizes.desktop[variant];
  }
  if (isTablet) {
    return sizes.tablet[variant];
  }
  return sizes.mobile[variant];
};

const sizes = {
  mobile: {
    big: { width: 152, height: 116 },
    small: { width: 152, height: 116 },
    new: { width: 152, height: 212 },
  },
  desktop: {
    big: { width: 202, height: 152 },
    small: { width: 164, height: 124 },
    tournaments: { width: 176, height: 132 },
    new: { width: 218, height: 280 },
  },
  tablet: {
    big: { width: 360, height: 272 },
    small: { width: 236, height: 176 },
    new: { width: 218, height: 280 },
  },
};

export default useGameCardSize;

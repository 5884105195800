import useAgentData from '~/client/hooks/useAgentData';
import DesktopSortButton from './DesktopSortButton';
import MobileSortButton from './MobileSortButton';
import type SortButtonProps from './SortButtonProps';

const GameCarouselSorter: FC<SortButtonProps> = (props) => {
  const { isMobile } = useAgentData();
  if (isMobile) {
    return <MobileSortButton {...props} />;
  }
  return <DesktopSortButton {...props} />;
};

export default GameCarouselSorter;

import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import useSite from '~/client/hooks/useSite';
import GameDetailsRow from './shared/GameDetailsRow';

type Props = Pick<SingleGameFragment, 'rtp'>;

const RtpRow: FC<Props> = ({ rtp }) => {
  const { isEmpire } = useSite();
  const isRtpVisible = !!rtp && !isEmpire;
  if (!isRtpVisible) {
    return null;
  }
  return <GameDetailsRow translateId="casino.rtp">{rtp}%</GameDetailsRow>;
};

export default RtpRow;

const sendEventToUmami = (event: Record<string, unknown>, name?: string) => {
  if (!window.imamu) {
    return;
  }

  console.log(`umami event: ${name || 'track'}`, { ...event });

  window.imamu(name || 'track', { ...event });
};
export default sendEventToUmami;

import GameDetailsInline from '~/client/shared/game/GameDetailsInline';
import Translate from '~/client/shared/i18n/Translate';

type Props = { translateId: string };

const GameDetailsRow: FC<Props> = ({ translateId, children }) => (
  <GameDetailsInline>
    <p className="text-moon-16 text-trunks">
      <Translate id={translateId} />
    </p>
    <p className="text-moon-16 text-bulma">{children}</p>
  </GameDetailsInline>
);

export default GameDetailsRow;

import getLanguageDir from '~/shared/utils/language/getLanguageDir';
import useLanguage from './useLanguage';

type ReturnProps = {
  isRtl: boolean;
  dir: 'rtl' | 'ltr';
};

const useIsRtl = (): ReturnProps => {
  const { code } = useLanguage();
  const dir = getLanguageDir(code);
  const isRtl = dir === 'rtl';

  return { isRtl, dir };
};

export default useIsRtl;

import type { CasinoGameConnectionSorter } from '~/schema/public';

const mapSortByToRtpField = (sortBy?: keyof CasinoGameConnectionSorter) => {
  if (sortBy?.includes('rtpLive')) {
    return sortBy as RtpField;
  }
  return undefined;
};

export default mapSortByToRtpField;

import useAppContext from '~/shared/context/AppContext/useAppContext';
import isBridgeSite from '~/shared/utils/site/isBridgeSite';
import useSiteConfig from './useSiteConfig';

const useSite = () => {
  const { site = 'bitcasino', brand = 'bitcasino' } = useAppContext();
  const siteConfig = useSiteConfig();
  const isLivecasino = site === 'livecasino';
  const isSportsbet = site === 'sportsbet';
  const isBitcasino = site === 'bitcasino';
  const isSbio = brand === 'sbio';
  const isSportsbetMx = site === 'sportsbetmx';
  const isEmpire = site === 'empire';
  return {
    site,
    brand,
    siteConfig,
    isLivecasino,
    isSportsbet,
    isBitcasino,
    isSbio,
    isBridgeSite: isBridgeSite(site),
    isSportsbetMx,
    isEmpire,
  };
};

export default useSite;

import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import Translate from '~/client/shared/i18n/Translate';
import isValidVolatility from '~/shared/utils/game/isValidVolatility';
import GameDetailsRow from './shared/GameDetailsRow';

type Props = Pick<SingleGameFragment, 'volatility'>;

const VolatilityRow: FC<Props> = ({ volatility }) => {
  if (!isValidVolatility(volatility)) {
    return null;
  }
  return (
    <GameDetailsRow translateId="casino.volatility">
      <Translate id={`casino.volatility_${volatility}`} />
    </GameDetailsRow>
  );
};

export default VolatilityRow;

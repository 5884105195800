import NextLink from '~/client/shared/NextLink';
import TextTrimmer from '~/client/shared/TextTrimmer';

type Props = { game: GameFragment };

const GameProvider: FC<Props> = ({ game }) => {
  const provider = game.menuItem?.[0];
  return (
    <NextLink href={`/providers/${provider?.handle}`}>
      <TextTrimmer>
        <p className="text-moon-10 font-medium uppercase text-trunks">
          {provider?.name ?? '\u00A0'}
        </p>
      </TextTrimmer>
    </NextLink>
  );
};

export default GameProvider;

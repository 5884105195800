import { useContext } from 'react';
import useRouter from '~/client/hooks/router/useRouter';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import TranslationCollectorContext from '~/shared/context/TranslationCollector/TranslationCollectorContext';
import isSsr from '~/shared/utils/isSsr';
import isWeirdKey from '~/shared/utils/isWeirdKey';
import useDidMount from '../lifecycle/useDidMount';

const useTranslationCollector = (key: string) => {
  const { translations } = useAppContext();
  const { collect } = useContext(TranslationCollectorContext);
  const { pathname } = useRouter();

  useDidMount(() => {
    if (!isSsr() && !isWeirdKey(key) && !translations[key]) {
      collect?.(pathname, key);
    }
  });
};

export default useTranslationCollector;

import { createRef, useCallback, useEffect, useState } from 'react';

const useHover = <T extends HTMLElement>(delay?: number) => {
  const [isHovered, setHovered] = useState(false);
  const [timeoutRef, setTimeoutRef] = useState(0);
  const hoverRef = createRef<T>();

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeoutRef);
    setHovered(true);
  }, [timeoutRef]);

  const handleMouseLeave = useCallback(() => {
    const timeout = window.setTimeout(() => setHovered(false), delay);
    setTimeoutRef(timeout);
  }, [delay]);

  useEffect(() => {
    const node = hoverRef.current;
    node?.addEventListener('mouseenter', handleMouseEnter);
    node?.addEventListener('mouseleave', handleMouseLeave);
    if (node?.matches(':hover')) {
      setHovered(true);
    }
    return () => {
      node?.removeEventListener('mouseenter', handleMouseEnter);
      node?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [handleMouseEnter, handleMouseLeave, hoverRef]);

  return { isHovered, hoverRef };
};

export default useHover;

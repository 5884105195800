import { mergeClassnames } from '@heathmont/moon-core-tw';
import useThemeSwitcher from '~/client/shared/ThemeSwitcher/useThemeSwitcher';

const CardBettingRangeDetail: FC = ({ children }) => {
  const { colorScheme } = useThemeSwitcher();
  const backgroundColor =
    colorScheme === 'light' ? 'bg-goku/[0.6]' : 'bg-goku/[0.04]';
  return (
    <div
      className={mergeClassnames(
        'absolute bottom-0 flex h-4 w-full items-center justify-center rounded-b-moon-i-sm left-0 right-0 text-center',
        'text-moon-10-caption font-medium uppercase text-bulma backdrop-blur-[20px]',
        backgroundColor,
      )}
    >
      {children}
    </div>
  );
};
export default CardBettingRangeDetail;

import { IconButton, mergeClassnames } from '@heathmont/moon-core-tw';
import { GenericInfo } from '@heathmont/moon-icons-tw';
import useNewThumbnailsEnabled from '~/client/hooks/feature/useNewThumbnailsEnabled';

const InfoButton: FC = () => {
  const newThumbsEnabled = useNewThumbnailsEnabled();
  return (
    <IconButton
      size="xs"
      icon={
        <GenericInfo
          data-testid="info-tooltip"
          className={mergeClassnames(
            'text-moon-24 text-trunks',
            newThumbsEnabled && 'text-popo text-moon-16',
          )}
        />
      }
      aria-label="Info"
      className={mergeClassnames(
        'p-0 bg-transparent hover:bg-transparent',
        newThumbsEnabled && 'h-5 w-5 bg-goten',
      )}
    />
  );
};

export default InfoButton;

import { mergeClassnames } from '@heathmont/moon-core-tw';
import useSite from '~/client/hooks/useSite';

const ContainerDesktop: FC = ({ children }) => {
  const { isBitcasino } = useSite();
  return (
    <div
      className={mergeClassnames(
        'rounded-moon-s-sm bg-gohan p-8 pb-5',
        '[&_div_h3]:m-0 [&_div_h3]:text-moon-24 [&_div_h3]:font-medium [&_div_h3]:leading-[1.25]',
        '[&_.two-columns_.group-inner]:grid [&_.two-columns_.group-inner]:grid-flow-row [&_.two-columns_.group-inner]:text-moon-14',
        '[&_.two-columns_.group-inner]:xl:grid-flow-initial [&_.two-columns_.group-inner]:xl:grid-cols-[1fr_1fr] [&_.two-columns_.group-inner]:xl:gap-x-14',
        '[&_.two-columns_.group-inner_p]:my-6',
        '[&_a]:font-medium [&_a]:text-trunks [&_a]:active:text-hit',
        isBitcasino && '[&_div_h3]:font-headline [&_div_h3]:font-normal',
      )}
    >
      {children}
    </div>
  );
};

export default ContainerDesktop;

import { useCallback, useState } from 'react';
import type { SingleGameFragment } from '~/client/components/Game/graphql/SingleGameFragment.generated';
import useCurrencyCode from '~/client/hooks/currency/useCurrencyCode';
import useHover from '~/client/hooks/events/useHover';
import useAgentData from '~/client/hooks/useAgentData';
import hasAnyData from './helpers/hasAnyData';

const MOUSE_OUT_TIMEOUT = 500;

const useGameDetails = (game: SingleGameFragment) => {
  const { isHovered, hoverRef } = useHover<HTMLDivElement>(MOUSE_OUT_TIMEOUT);
  const { isTablet } = useAgentData();
  const [visible, setVisible] = useState(false);
  const handleClick = useCallback(() => {
    if (isTablet) {
      setVisible(true);
    }
  }, [isTablet]);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const currencyCode = useCurrencyCode();
  const hasData = hasAnyData(game, currencyCode);
  return {
    hasData,
    isHovered,
    hoverRef,
    visible,
    handleClick,
    handleClose,
  };
};

export default useGameDetails;

import { mergeClassnames } from '@heathmont/moon-core-tw';
import useSite from '~/client/hooks/useSite';
import Translate from '~/client/shared/i18n/Translate';
import NextLink from '~/client/shared/NextLink';
import type PageSectionProps from '~/client/shared/PageSection/PageSectionProps';
import sendInteractionToGtm from '~/shared/gtm/sendInteractionToGtm';

const TitleIdPlaceholder: FC<PageSectionProps> = ({
  titleId,
  href,
  gtmLocation,
}) => {
  const { siteConfig, isBitcasino } = useSite();

  const sendToGtm = () =>
    sendInteractionToGtm({
      eventCategory: 'text_link',
      eventAction: gtmLocation ?? 'games_slider',
    });

  if (titleId && href) {
    return (
      <NextLink href={href} onClick={sendToGtm}>
        <h4
          className={mergeClassnames(
            'whitespace-nowrap text-moon-20 font-semibold',
            isBitcasino && 'font-headline font-light',
          )}
        >
          {titleId && (
            <Translate id={titleId} values={{ siteName: siteConfig.name }} />
          )}
        </h4>
      </NextLink>
    );
  }
  return null;
};

export default TitleIdPlaceholder;

import { Tooltip } from '@heathmont/moon-core-tw';
import type InfoTooltipProps from './types';

const DesktopInfoTooltip: FC<InfoTooltipProps> = ({ trigger, content }) => (
  <Tooltip>
    <Tooltip.Trigger>
      <span>{trigger}</span>
    </Tooltip.Trigger>
    <Tooltip.Content className="z-20 p-4 rounded-moon-i-sm">
      {content}
      <Tooltip.Arrow />
    </Tooltip.Content>
  </Tooltip>
);

export default DesktopInfoTooltip;

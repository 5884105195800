/* eslint-disable no-restricted-imports */
import {
  betaddaDark,
  betaddaLight,
  bitcasinoDark,
  bitcasinoLight,
  livecasinoDark,
  livecasinoLight,
  moneyballIoDark,
  moneyballIoLight,
  sportsbetDark,
  sportsbetLight,
  bombayClub,
  empireDark,
  empireLight,
} from '@heathmont/moon-themes';

const themes = {
  betaddaDark,
  betaddaLight,
  bitcasinoDark,
  bitcasinoLight,
  livecasinoDark,
  livecasinoLight,
  moneyballIoDark,
  moneyballIoLight,
  sportsbetDark,
  sportsbetLight,
  empireDark,
  empireLight,
  bombayClub,
};

const getTheme = (name: keyof typeof themes): Theme => ({
  ...themes[name],
  name,
});

export default getTheme;

import FavouriteToggle from '../../FavouriteToggle';
import InfoTooltip from '../../GameCard/GameInfo/InfoTooltip';

type Props = {
  game: GameFragment;
  isFavouriteToggleVisible: boolean;
};

const ActionIcons = ({ game, isFavouriteToggleVisible }: Props) => (
  <div className="flex flex-col items-center gap-1 absolute sm:top-3 sm:right-3 top-2 right-2 z-1">
    {isFavouriteToggleVisible && <FavouriteToggle game={game} />}
    <InfoTooltip game={game} />
  </div>
);

export default ActionIcons;
